import React, { useState, useEffect, useMemo } from 'react';
import { Content, Row, Col, Sidebar } from 'components';
import { TreeSelect } from 'antd';
// import Dashboard from './Dashboard';
import Myinfo from './MyInfo';
import MySchedule from './MySchedule';
import Profile from './Profile';
import MyLearning from './MyLearning';
import MyTest from './MyTest';
import MyPosts from './MyPosts';
import LectureCart from './LectureCart';
import LectureFavorites from './LectureFavorites';
import LectureOrders from './LectureOrders';
import LectureList from './LectureList';
import LectureJournal from './LectureJournal';
import ProductCart from './ProductCart';
import Purchase from './Purchase';
import EventList from './EventList';
import MyCalculate from './MyCalculate';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import { getCookie, deleteCookie } from 'utils';
import { useQueryClient } from 'react-query';
import { encrypt } from 'utils/Crypto';
import MessageAlert from 'utils/MessageAlert';

const MypageRoutes = () => {
  /* ===== ROUTE ===== */
  const navigate = useNavigate();

  /* ===== STATE ===== */
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');
  // 접속자 확인 (수강생, 강사, 기관)
  const loginWho = useRecoilValue(loginUser);
  const [data, setData] = useState({});
  const [sideMenu, setSideMenu] = useState([]);
  // SideMenu 아이템
  const items = useMemo(() => {
    const list = {
      user: [
        {
          title: '',
          menus: [
            {
              label: '내 정보',
              path: '/mypage/my_info',
            },
            {
              label: '참여 이벤트',
              path: '/mypage/events',
            },
          ],
        },
        {
          title: '학습관리',
          menus: [
            {
              label: '나의 학습',
              path: '/mypage/my_learning',
            },
            {
              label: '온라인 테스트',
              path: '/mypage/my_test',
            },
            {
              label: '내가 작성한 리뷰',
              path: '/mypage/my_posts',
            },
          ],
        },
        {
          title: '수강관리',
          menus: [
            {
              label: '수강바구니',
              path: '/mypage/lecture/cart',
            },
            {
              label: '찜목록',
              path: '/mypage/lecture/favorites',
            },
            {
              label: '구매내역',
              path: '/mypage/lecture/orders',
            },
          ],
        },
        {
          title: '상품',
          menus: [
            {
              label: '장바구니',
              path: '/mypage/product/cart',
            },
            {
              label: '구매내역',
              path: '/mypage/product/purchase',
            },
          ],
        },
      ],
      teacher: [
        {
          title: '',
          menus: [
            {
              label: '내 정보',
              path: '/mypage/my_info',
            },
            {
              label: '참여 이벤트',
              path: '/mypage/events',
            },
            {
              label: '정산내역',
              path: '/mypage/my_calculate',
            },
          ],
        },
        {
          title: '강사 메뉴',
          menus: [
            {
              label: '프로필',
              path: '/mypage/profile',
            },
            {
              label: '프로필 관리',
              path: data?.profile
                ? `/profile?profile=${encodeURIComponent(
                    encrypt(getCookie('USER'))
                  )}`
                : '/profile',
            },
            {
              label: '등록 강의',
              path: '/mypage/my_lecture',
            },
            {
              label: '일정 관리',
              path: '/mypage/my_schedule',
            },
          ],
        },
        {
          title: '학습관리',
          menus: [
            {
              label: '나의 학습',
              path: '/mypage/my_learning',
            },
            {
              label: '온라인 테스트',
              path: '/mypage/my_test',
            },
            {
              label: '내가 작성한 리뷰',
              path: '/mypage/my_posts',
            },
          ],
        },
        {
          title: '수강관리',
          menus: [
            {
              label: '수강바구니',
              path: '/mypage/lecture/cart',
            },
            {
              label: '찜목록',
              path: '/mypage/lecture/favorites',
            },
            {
              label: '구매내역',
              path: '/mypage/lecture/orders',
            },
          ],
        },
        {
          title: '상품',
          menus: [
            {
              label: '장바구니',
              path: '/mypage/product/cart',
            },
            {
              label: '구매내역',
              path: '/mypage/product/purchase',
            },
          ],
        },
      ],
      agency: [
        {
          title: '',
          menus: [
            {
              label: '내 정보',
              path: '/mypage/my_info',
            },
            {
              label: '일정 관리',
              path: '/mypage/my_schedule',
            },
            {
              label: '청구내역',
              path: '/mypage/my_calculate',
            },
          ],
        },
        {
          title: '상품',
          menus: [
            {
              label: '장바구니',
              path: '/mypage/product/cart',
            },
            {
              label: '구매내역',
              path: '/mypage/product/purchase',
            },
          ],
        },
      ],
    };

    switch (loginWho) {
      case 'user':
      case 'User':
      case 'USER':
        return list.user;
      case 'teacher':
      case 'Teacher':
      case 'TEACHER':
        return list.teacher;
      case 'agency':
      case 'Agency':
      case 'AGENCY':
        return list.agency;
      default:
        break;
    }
  }, [loginWho, data]);

  const queryClient = useQueryClient();
  const users = queryClient.getQueryData('getUsers')?.data;
  const profiles = queryClient.getQueryData('getUserProfiles')?.data;
  const lectures = queryClient.getQueryData('getLectures')?.data;
  const schedules = queryClient.getQueryData('getSchedules')?.data;
  const preference_area = queryClient.getQueryData(
    'getPreferenceAreaUsers'
  )?.data;
  const preference_subway = queryClient.getQueryData(
    'getPreferenceSubwayUsers'
  )?.data;
  const sns = queryClient.getQueryData('getSNSUsers')?.data;
  const exams = queryClient.getQueryData('getExams')?.data;
  const agencies = queryClient.getQueryData('getAgencies')?.data;
  const major = queryClient.getQueryData('getUserMajors')?.data;
  const categories = queryClient.getQueryData('getCategories')?.data;
  const lectureFiles = queryClient.getQueryData('getLectureFiles')?.data;
  const teacherFiles = queryClient.getQueryData('getTeacherFiles')?.data;
  const answers = queryClient.getQueryData('getAnswerExams')?.data;
  const events = queryClient.getQueryData('getEvents')?.data;
  const eventOfUsers = queryClient.getQueryData('getEventOfUsers')?.data;
  const address = queryClient.getQueryData('getAddressSplit')?.data;
  const programs = queryClient.getQueryData('getPrograms')?.data;
  const [scheduleCount, setScheduleCount] = useState(0);

  const d = useMemo(() => {
    const local = address?.local;
    const city = address?.city;
    const district = address?.district;
    const idx = parseInt(getCookie('USER'));
    const l = lectures?.filter((item) => item.user_id === idx);
    const ex = l?.reduce((acc, item) => {
      const filteredLecture = exams?.filter((subitem) => {
        return subitem.lecture_id === item.lecture_id;
      });
      return acc.concat(filteredLecture);
    }, []);

    const newAgencies = agencies?.map((item) => ({
      ...item,
      local_name: local
        ?.filter((subitem) => item?.local_id === subitem?.local_id)
        ?.pop()?.local_name,
      city_name: city
        ?.filter((subitem) => item?.city_id === subitem?.city_id)
        ?.pop()?.city_name,
      district_name: district
        ?.filter((subitem) => item?.district_id === subitem?.district_id)
        ?.pop()?.district_name,
    }));

    const majorItem = major?.map((item) => ({
      ...item,
      major_name: categories
        ?.filter((subitem) => subitem?.category_id === item?.major)
        ?.pop()?.category_name,
      major_detail_name: categories
        ?.filter((subitem) => subitem.category_id === item?.major_detail)
        ?.pop()?.category_name,
    }));

    const e = eventOfUsers?.map((item) => ({
      ...item,
      ...(events &&
        events?.filter((subitem) => item?.event_id === subitem?.event_id)[0]),
    }));

    switch (loginWho) {
      case 'user':
      case 'User':
      case 'USER':
        return {
          user: users?.filter((item) => item.user_id === idx)?.pop(),
          lectures: l?.map((item) => ({
            ...item,
            files: lectureFiles?.filter(
              (subitem) => item?.lecture_id === subitem?.lecture_id
            ),
            category: categories?.filter(
              (subitem) => subitem?.category_id === item?.category_id
            )[0]?.category_name,
          })),
          events: e?.filter((item) => item?.user_id === idx),
        };
      case 'teacher':
      case 'Teacher':
      case 'TEACHER':
        return {
          user: users?.filter((item) => item?.user_id === idx)?.pop(),
          profile: profiles?.filter((item) => item?.user_id === idx)?.pop(),
          lectures: l?.map((item) => ({
            ...item,
            ...(
              users &&
              users?.filter((subitem) => item.user_id === subitem.user_id)
            )?.pop(),
            files: lectureFiles?.filter(
              (subitem) => item?.lecture_id === subitem?.lecture_id
            ),
            category: categories?.filter(
              (subitem) => subitem?.category_id === item?.category_id
            )[0]?.category_name,
          })),
          schedules: schedules
            ?.filter((item) => item?.user_id === idx)
            ?.map((item) => ({
              ...item,
              ...(newAgencies &&
                newAgencies?.filter(
                  (subitem) => subitem.agency_id === item.agency_id
                )[0]),
              category_name: categories?.filter(
                (subitem) => item?.category_id === subitem?.category_id
              )[0]?.category_name,
            })),
          preference_area: preference_area?.filter(
            (item) => item?.user_id === idx
          ),
          preference_subway: preference_subway?.filter(
            (item) => item?.user_id === idx
          ),
          sns: sns?.filter((item) => item?.user_id === idx),
          exams: ex,
          major: majorItem?.filter((item) => item.user_id === idx),
          teacherFiles: teacherFiles?.filter((item) => item?.user_id === idx),
          answers: answers,
          events: e?.filter((item) => item?.user_id === idx),
          programs: programs,
        };
      case 'agency':
      case 'Agency':
      case 'AGENCY':
        return {
          schedules: schedules
            ?.filter((item) => item?.agency_id === idx)
            ?.map((item) => ({
              ...item,
              ...(users &&
                users?.filter(
                  (subitem) => subitem.user_id === item.user_id
                )[0]),
              category_name: categories?.filter(
                (subitem) => item?.category_id === subitem?.category_id
              )[0]?.category_name,
            })),
          agency: agencies?.filter((item) => item?.agency_id === idx)?.pop(),
          programs: programs,
          profiles: profiles?.map((item) => ({
            ...item,
            profileImage: teacherFiles
              ?.filter(
                (subitem) =>
                  item?.user_id === subitem?.user_id && subitem?.type === 'P'
              )
              ?.pop(),
            attachmentFiles: teacherFiles?.filter(
              (subitem) =>
                item?.user_id === subitem?.user_id && subitem?.type === 'E'
            ),
            certificateFiles: teacherFiles?.filter(
              (subitem) =>
                item?.user_id === subitem?.user_id && subitem?.type === 'C'
            ),
          })),
        };
      default:
        break;
    }
  }, [
    loginWho,
    users,
    profiles,
    schedules,
    lectures,
    preference_area,
    preference_subway,
    sns,
    exams,
    agencies,
    major,
    categories,
    lectureFiles,
    teacherFiles,
    answers,
    events,
    eventOfUsers,
    address,
    programs,
  ]);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [treeValue, setTreeValue] = useState('');

  /* ===== FUNCTION ===== */

  /* ===== HOOKS ===== */
  // 마이페이지 사이드 메뉴 렌더링
  useEffect(() => {
    setSideMenu(items);
  }, [items]);

  // 로그인 고객 데이터 추출
  useEffect(() => {
    setData(d);
  }, [d]);

  /**
   * 비로그인 라우팅 처리
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      deleteCookie('BRAINHEAL_ACCESS_TOKEN', {});
      deleteCookie('USER', {});
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }
  }, [token, navigate, uid]);

  /**
   * 요청받은 스케줄 카운트
   * --
   */
  useEffect(() => {
    if (token && (loginWho === 'teacher' || loginWho === 'agency')) {
      const filterSchedules = schedules?.filter((item) => {
        if (loginWho === 'teacher') {
          return item?.user_id === parseInt(getCookie('USER'));
        } else {
          return item?.agency_id === parseInt(getCookie('USER'));
        }
      });

      const newSchedules = filterSchedules?.filter(
        (item) =>
          item?.schedule_type === 'R' &&
          (loginWho === 'teacher'
            ? item?.user_confirm === 'C'
            : item?.agency_confirm === 'C')
      );
      setScheduleCount(newSchedules?.length);
    }
  }, [token, loginWho, schedules]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== RENDER ===== */
  return (
    <Content maxWidth={'100%'} padding={0} style={{ margin: '48px 0 84px' }}>
      <Content padding={15} maxWidth={1200}>
        <Row>
          {/* 사이드 메뉴 */}
          <Col x={24} md={4}>
            {screenSize >= 768 ? (
              <Sidebar items={sideMenu} scheduleCount={scheduleCount} />
            ) : (
              <div style={{ padding: '0 12px' }}>
                <TreeSelect
                  style={{
                    width: '100%',
                    marginBottom: '30px',
                  }}
                  value={treeValue}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                  }}
                  treeData={sideMenu?.map((item) => ({
                    label: item?.title === '' ? '내정보' : item?.title,
                    value: item?.title,
                    selectable: false,
                    children: item?.menus?.map((subitem) => ({
                      label: subitem?.label,
                      value: subitem?.path,
                    })),
                  }))}
                  placeholder="Please select"
                  treeDefaultExpandAll
                  onChange={(e) => {
                    setTreeValue(e);
                    navigate(e);
                  }}
                  size="large"
                />
              </div>
            )}
          </Col>
          <Col x={24} md={20}>
            <Routes>
              {/* <Route path="dashboard" element={<Dashboard />} /> */}
              <Route path="my_info" element={<Myinfo data={data} />} />
              <Route
                path="events"
                element={<EventList data={data} screenSize={screenSize} />}
              />
              <Route
                path="my_calculate"
                element={<MyCalculate data={data} screenSize={screenSize} />}
              />
              <Route path="my_schedule" element={<MySchedule data={data} />} />
              <Route path="profile" element={<Profile data={data} />} />
              <Route path="my_learning" element={<MyLearning />} />
              <Route
                path="my_test"
                element={<MyTest screenSize={screenSize} />}
              />
              <Route
                path="my_posts"
                element={<MyPosts screenSize={screenSize} />}
              />
              <Route
                path="my_lecture"
                element={<LectureList data={data} screenSize={screenSize} />}
              />
              <Route path="my_lecture_journal" element={<LectureJournal />} />
              <Route path="/lecture/*">
                <Route
                  path="cart"
                  element={<LectureCart loginWho={loginWho} />}
                />
                <Route
                  path="favorites"
                  element={<LectureFavorites loginWho={loginWho} />}
                />
                <Route path="orders" element={<LectureOrders />} />
              </Route>
              <Route path="/product/*">
                <Route
                  path="cart"
                  element={<ProductCart loginWho={loginWho} />}
                />
                <Route path="purchase" element={<Purchase />} />
              </Route>
            </Routes>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

export default MypageRoutes;
