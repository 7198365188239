import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import MySchedulePresenter from './MySchedulePresenter';
import { getCookie } from 'utils';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import { ScheduleApi, CalculateApi, UserApi, AgencyApi } from 'api';

const MyScheduleContainer = ({ data }) => {
  /* ===== ROUTE ===== */
  /* ===== PROPS ===== */
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);

  // 접속자 확인 (수강생, 강사, 기관)

  /* ===== STATE ===== */
  const uid = getCookie('USER');
  const queryClient = useQueryClient();
  const loginWho = useRecoilValue(loginUser);
  const { data: schedules, refetch: scheduleRefetch } =
    ScheduleApi.GetSchedules(
      loginWho === 'agency'
        ? {
            agency_id: parseInt(uid),
            date: `${year}-${month}`,
          }
        : {
            user_id: parseInt(uid),
            date: `${year}-${month}`,
          }
    );
  const { data: calculate, refetch: calculateRefetch } =
    CalculateApi.GetCalculates(
      loginWho === 'agency'
        ? {
            agency_id: parseInt(uid),
            date: `${year}-${month}`,
          }
        : {
            user_id: parseInt(uid),
            date: `${year}-${month}`,
          }
    );
  const userInfo = UserApi.GetUser(uid, null, {
    enabled: !!uid && loginWho === 'teacher',
  })?.data;
  const agencyInfo = AgencyApi.GetAgency(uid, null, {
    enabled: !!uid && loginWho === 'agency',
  })?.data;
  const categories = queryClient.getQueryData('getCategories')?.data;
  const agencies = queryClient.getQueryData('getAgencies')?.data;
  const users = queryClient.getQueryData('getUsers')?.data;
  const repetitionSchedules = queryClient.getQueryData(
    'getRepetitionSchedules'
  )?.data;
  const [dataState, setDataState] = useState({});

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  useEffect(() => {
    if (schedules?.status === 200 && categories) {
      // 카테고리 데이터 정제
      const m = categories?.filter((item) => item?.type === 'M');
      const d = categories?.filter((item) => item?.type === 'D');
      // 유저 데이터 정제
      const usersFilter = users?.filter((item) => item?.is_show === 'Y');
      const filterUserData = usersFilter?.map((item) => ({
        user_id: item?.user_id,
        name: item?.name,
        signature: item?.signature,
      }));
      // 기관 데이터 정제
      const agenciesFilter = agencies?.filter(
        (item) => item?.is_show === 'Y' && item?.is_enable === 'Y'
      );
      const filterAgencyData = agenciesFilter?.map((item) => ({
        agency_id: item?.agency_id,
        agency_name: item?.name,
        agency_address: item?.address,
        agency_address_detail: item?.address_detail,
        agency_type: item?.type,
        agency_phone: item?.phone,
        agency_email: item?.email,
        agency_signature: item?.signature,
      }));
      // 반복스케줄 데이터 정제
      const newRepetitionSchedules = repetitionSchedules
        ?.filter((item) => item?.user_id === parseInt(uid))
        ?.map((item) => ({
          ...item,
          ...(filterUserData &&
            filterUserData?.filter(
              (subitem) => subitem?.user_id === item?.user_id
            )[0]),
          ...(filterAgencyData &&
            filterAgencyData?.filter(
              (subitem) => subitem?.agency_id === item?.agency_id
            )[0]),
          category_name: categories?.filter(
            (subitem) => subitem?.category_id === item?.category_id
          )[0]?.category_name,
        }));
      // 스케줄 데이터 정제
      const newSchedules = schedules?.data
        ?.filter((item) =>
          loginWho === 'agency'
            ? item?.agency_id === parseInt(uid)
            : item?.user_id === parseInt(uid)
        )
        ?.map((item) =>
          loginWho === 'teacher'
            ? {
                ...item,
                ...(filterUserData &&
                  filterUserData?.filter(
                    (subitem) => subitem?.user_id === item?.user_id
                  )[0]),
                ...(filterAgencyData &&
                  filterAgencyData?.filter(
                    (subitem) => subitem?.agency_id === item?.agency_id
                  )[0]),
                category_name: categories?.filter(
                  (subitem) => subitem?.category_id === item?.category_id
                )[0]?.category_name,
              }
            : {
                ...item,
                agency_name: agencyInfo?.data?.name,
                agency_signature: agencyInfo?.data?.signature,
                ...(users &&
                  users?.filter(
                    (subitem) => subitem.user_id === item.user_id
                  )[0]),
                category_name: categories?.filter(
                  (subitem) => subitem?.category_id === item?.category_id
                )[0]?.category_name,
              }
        );

      const Data = {
        myData: loginWho === 'agency' ? agencyInfo?.data : userInfo?.data,
        schedules: newSchedules,
        calculate: calculate?.data,
        major: m,
        major_detail: d,
        agencies: agenciesFilter,
        weeklyItems: newRepetitionSchedules,
        profiles: data?.profiles,
      };

      setDataState(Data);
    }
  }, [
    categories,
    schedules,
    calculate,
    agencies,
    repetitionSchedules,
    data?.profiles,
    uid,
    loginWho,
    users,
    userInfo,
    agencyInfo,
  ]);

  /**
   * 년/월에 따른 스케쥴/정산 데이터 호출
   * --
   */
  useEffect(() => {
    scheduleRefetch();
    calculateRefetch();
  }, [uid, year, month, scheduleRefetch, calculateRefetch]);

  /* ===== RENDER ===== */
  return (
    <MySchedulePresenter
      myData={dataState?.myData}
      loginWho={loginWho}
      year={year}
      setYear={setYear}
      month={month}
      setMonth={setMonth}
      schedules={dataState.schedules}
      calculate={dataState.calculate}
      major={dataState.major}
      majorDetail={dataState.major_detail}
      weeklyItems={dataState.weeklyItems}
      agencies={dataState?.agencies}
      profiles={dataState?.profiles}
    />
  );
};

MyScheduleContainer.defaultProps = {};

export default MyScheduleContainer;
