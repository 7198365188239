import React, { useEffect, useState, useMemo } from 'react';
import { Content, Row, Col, Calendar, Title } from 'components';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import { useParams, useNavigate } from 'react-router-dom';
import { decrypt } from 'utils/Crypto';
import { CalculateApi, ScheduleApi, UserApi } from 'api';
import { useQueryClient } from 'react-query';
import MessageAlert from 'utils/MessageAlert';
import { getCookie, handleDeleteCheck } from 'utils';
import moment from 'moment';
function Schedule() {
  /* ===== ROUTE ===== */
  const { teacherId } = useParams();
  const navigate = useNavigate();

  /* ===== STATE ===== */
  const queryClient = useQueryClient();
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const { data: schedules, refetch } = ScheduleApi.GetSchedules({
    user_id: parseInt(decrypt(teacherId)),
    date: `${year}-${month}`,
  });
  const { data: calculate, refetch: calculateRefetch } =
    CalculateApi.GetCalculates({
      user_id: parseInt(decrypt(teacherId)),
      date: `${year}-${month}`,
    });
  const { data: teacher, refetch: teacherRefetch } = UserApi.GetUser(
    parseInt(decrypt(teacherId))
  );
  const agencies = queryClient.getQueryData('getAgencies')?.data;
  const categories = queryClient.getQueryData('getCategories')?.data;
  // console.log('teacher', teacher);
  const loginWho = useRecoilValue(loginUser);
  // 캘린더 아이템
  const [events, setEvents] = useState([]);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const items = useMemo(() => {
    const newAgencies = agencies?.map((item) => ({
      agency_id: item?.agency_id,
      agency_name: item?.name,
      agency_address: item?.address,
      agency_address_detail: item?.address_detail,
      agency_type: item?.type,
      agency_phone: item?.phone,
      agency_email: item?.email,
      chief_name: item?.chief_name,
    }));

    const newSchedules = schedules?.data?.map((item) => ({
      ...item,
      ...(newAgencies &&
        newAgencies?.filter(
          (subitem) => subitem.agency_id === item.agency_id
        )[0]),
      category_name: categories?.filter(
        (subitem) => subitem?.category_id === item?.category_id
      )[0]?.category_name,
    }));

    const s = newSchedules?.map((item) => ({
      id: item?.schedule_id,
      title: `${item?.category_name} (${moment(item?.start_date).format(
        'HH:mm'
      )} ~ ${moment(item?.end_date).format('HH:mm')})`,
      start: new Date(item?.start_date),
      end: new Date(item?.end_date),
      user_confirm: item?.user_confirm,
      agency_confirm: item?.agency_confirm,
      type: item?.schedule_type,
      content: item?.content,
      name: `${item?.agency_name} (${item?.agency_type})`,
      email: item?.agency_email,
      phone: item?.agency_phone,
      file: item?.file,
      chief_name: item?.chief_name,
      address: item?.agency_address,
      address_detail: item?.address_detail,
      agency_id: item?.agency_id,
      registrant: item?.registrant,
      user_id: item?.user_id,
      category_id: item?.category_id,
      category_name: item?.category_name,
      is_calculate: handleDeleteCheck(item, calculate?.data),
    }));
    return s;
  }, [schedules, agencies, categories, calculate]);

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  useEffect(() => {
    refetch();
    calculateRefetch();
  }, [teacherId, refetch, year, month, calculateRefetch]);

  useEffect(() => {
    teacherRefetch();
  }, [teacherId, teacherRefetch]);

  useEffect(() => {
    setEvents(items);
  }, [items]);

  /**
   * 로그인 토큰 검사
   * --
   */
  useEffect(() => {
    if (!getCookie('BRAINHEAL_ACCESS_TOKEN')) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }
  }, [navigate]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== RENDER ===== */
  return (
    <Content
      maxWidth={'100%'}
      padding={0}
      backgroundColor={'#F5F5F5'}
      style={{ padding: '48px 0 84px' }}
    >
      <Content
        maxWidth={1200}
        padding={screenSize >= 1200 ? 0 : '0 30px'}
        backgroundColor={'none'}
      >
        <Row>
          <Col x={24}>
            <Title
              align="center"
              style={{
                fontSize: screenSize >= 768 ? 35 : 18,
                fontWeight: 700,
                lineHeight: '42px',
                letterSpacing: '0.02em',
                marginBottom: '51px',
              }}
            >
              {`${teacher?.data?.name}`} 강사님의 현재 스케쥴
            </Title>
          </Col>
          <Col x={24}>
            <Content
              maxWidth={'100%'}
              padding={0}
              height={screenSize >= 768 ? 973 : 600}
            >
              <Calendar
                items={events}
                teacherId={teacherId ? parseInt(decrypt(teacherId)) : null}
                height={'100%'}
                background={'#F5F5F5'}
                request={loginWho === 'agency' ? true : false}
                add={loginWho === 'admin' ? true : false}
                update={loginWho === 'admin' ? true : false}
                detail={true}
                agencyView={false}
                confirm={loginWho === 'admin' ? true : false}
                setMonth={setMonth}
                setYear={setYear}
                program={
                  parseInt(decrypt(teacherId)) === parseInt(getCookie('USER'))
                }
                agencyDetail={
                  parseInt(decrypt(teacherId)) ===
                    parseInt(getCookie('USER')) || loginWho === 'admin'
                }
              />
            </Content>
          </Col>
        </Row>
      </Content>
    </Content>
  );
}

Schedule.defaultProps = {};

export default Schedule;
