import React, { useState, useEffect, useMemo } from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  Calendar,
  Statistic,
  Button,
  ModalLayout,
} from 'components';
import {
  List,
  Input,
  Avatar,
  Button as Btn,
  Collapse,
  // Checkbox
} from 'antd';
import MessageAlert from 'utils/MessageAlert';
import { DOMAIN_URL, handleDeleteCheck, stringToMoneyFormat } from 'utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { decrypt, encrypt } from 'utils/Crypto';
import { CalculateApi, ScheduleApi, TotalCalculateApi } from 'api';
import {
  LeftOutlined,
  RightOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import UseDebounce from 'utils/useDebounce';
import DOMPurify from 'dompurify';

const AgencyCalculatePresenter = (props) => {
  /* ===== PROPS ===== */
  const {
    teachers,
    agencies,
    categories,
    onExcelDownload,
    onDownload,
    onPDFDownload,
    opened,
    setOpened,
    htmlTemplate,
  } = props;

  /* ===== ROUTE ===== */
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const date = searchParams.get('date');

  /* ===== STATE ===== */
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = UseDebounce(searchValue, 300);
  const [isOpened, setIsOpened] = useState(false);
  const [isCalculateModal, setIsCalculateModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [agencyList, setAgencyList] = useState([]);
  const [filterAgencyList, setFilterAgencyList] = useState([]);
  const [selectAgencyId, setSelectAgencyId] = useState(0);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [eventItems, setEventItems] = useState([]);
  const [progress, setProgress] = useState([]);
  const [calculateData, setCalculateData] = useState([]);
  const [calModalData, setCalModalData] = useState({});
  const [calInputData, setCalInputData] = useState({
    work_hour: null,
    time_price: 40000,
    agency_time_price: 55000,
  });
  const [isPopup, setIsPopup] = useState(false);
  const [totalCalculateData, setTotalCalculateData] = useState({});
  // const [checkAll, setCheckAll] = useState(false);
  // const [checkList, setCheckList] = useState([]);
  const [pdfType, setPdfType] = useState('');

  /* ===== MUTATE ===== */
  const { data: schedules, refetch: scheduleRefetch } =
    ScheduleApi.GetSchedules({
      agency_id: parseInt(selectAgencyId),
      date: `${year}-${month}`,
    });
  const { data: calculate, refetch: calculateRefetch } =
    CalculateApi.GetCalculates({
      agency_id: parseInt(selectAgencyId),
      date: `${year}-${month}`,
    });
  const createCalculate = CalculateApi.CreateCalculate();
  const updateCalculate = CalculateApi.UpdateCalculate();
  const deleteCalculate = CalculateApi.DeleteCalculate();
  const { data: totalCalculate, refetch: totalCalculateRefetch } =
    TotalCalculateApi.GetTotalCalculates({
      agency_id: parseInt(selectAgencyId),
    });
  const updateTotalCalculate = TotalCalculateApi.UpdateTotalCalculate();
  /**
   * 캘린더 아이템 정제
   * --
   */
  const events = useMemo(() => {
    const newAgency = agencies
      ?.filter((item) => item?.agency_id === selectAgencyId)
      ?.map((item) => ({
        agency_id: item?.agency_id,
        agency_name: item?.name,
        agency_address: item?.address,
        agency_address_detail: item?.address_detail,
        agency_type: item?.type,
        agency_phone: item?.phone,
        agency_email: item?.email,
        chief_name: item?.chief_name,
      }))[0];

    const newSchedules = schedules?.data?.map((item) => ({
      ...item,
      ...newAgency,
      category_name: categories?.filter(
        (subitem) => subitem?.category_id === item?.category_id
      )[0]?.category_name,
      ...(teachers &&
        teachers?.filter((subitem) => subitem.user_id === item.user_id)[0]),
    }));

    const s = newSchedules?.map((item, index) => ({
      id: item?.schedule_id,
      title: `${item?.category_name} (${item?.name})`,
      start: new Date(item?.start_date),
      end: new Date(item?.end_date),
      user_confirm: item?.user_confirm,
      agency_confirm: item?.agency_confirm,
      type: item?.schedule_type,
      content: item?.content,
      name: `${item?.name}`,
      email: item?.agency_email,
      phone: item?.agency_phone,
      file: item?.file,
      chief_name: item?.chief_name,
      address: item?.agency_address,
      address_detail: item?.address_detail,
      agency_id: item?.agency_id,
      registrant: item?.registrant,
      user_id: item?.user_id,
      category_id: item?.category_id,
      category_name: item?.category_name,
      is_calculate: handleDeleteCheck(item, calculate?.data),
    }));
    return s;
  }, [schedules, agencies, categories, selectAgencyId, teachers, calculate]);

  /* ===== FUNCTION ===== */
  /**
   * 진행시간 계산
   * --
   */
  const handleCalculateTime = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const timeDifference = endDate - startDate;
    const hours = timeDifference / (1000 * 60 * 60);

    return hours;
  };

  /**
   * 날짜 값 변경
   * --
   */
  const handleDate = (type) => {
    let m = month,
      y = year;
    if (type === 'prev') {
      --m;

      if (m === 0) {
        m = 12;
        --y;
      }
    } else {
      ++m;

      if (m > 12) {
        m = 1;
        ++y;
      }
    }
    navigation(
      `/admin/agency-calculate?date=${y}-${m}${
        selectAgencyId &&
        `&agency=${encodeURIComponent(encrypt(selectAgencyId))}`
      }`
    );
  };

  /**
   * 정산 모달
   * --
   */
  const handleCalculateModal = (data) => {
    setCalModalData(data);
    setIsCalculateModal(!isCalculateModal);
    if (data?.calculate?.calculate_id) {
      // 정산 된 값이 있는 경우
      setCalInputData(data?.calculate);
    } else {
      /**
       * NOTE: 정산 된 값이 없는 경우 defaultValue 설정
       * 정산시간 - 강사와 기관 확인이 둘다 되어있는 스케쥴 진행시간 합
       * 정산 시급 - 55,000
       */
      const totalTime = data?.schedules?.reduce((acc, cur) => {
        if (cur?.user_check)
          return acc + handleCalculateTime(cur.start_date, cur.end_date);
        else return acc + 0;
      }, 0);
      setCalInputData({
        work_hour: totalTime,
        time_price: 40000,
        agency_time_price: 55000,
      });
    }
  };

  /**
   * 데이터 바인딩 함수
   * --
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...calInputData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setCalInputData(d);

    if (callback) callback(v);
  };

  /**
   * 정산 데이터 관리
   * --
   */
  const handleCalculate = () => {
    const { work_hour, time_price, agency_time_price, calculate_id } =
      calInputData;
    if (!work_hour) return MessageAlert.warning('정산할 시간을 입력해주세요');
    if (isNaN(work_hour))
      return MessageAlert.warning('정산할 시간을 숫자로만 입력해주세요');

    if (!time_price) return MessageAlert.warning('강사 시급을 입력해주세요');
    if (isNaN(time_price))
      return MessageAlert.warning('강사 시급을을 숫자로만 입력해주세요');

    if (!agency_time_price)
      return MessageAlert.warning('기관 시급을 입력해주세요');
    if (isNaN(agency_time_price))
      return MessageAlert.warning('기관 시급을을 숫자로만 입력해주세요');

    const type = calculate_id ? 'U' : 'C';
    const confirm = window.confirm(
      calculate_id
        ? '해당 스케쥴에 대한 정산 내용을 수정하시겠습니까?'
        : '해당 스케쥴에 대해 정산하시겠습니까?'
    );
    if (confirm) {
      try {
        if (type === 'U') {
          updateCalculate.mutate({
            calculate_id,
            work_hour,
            time_price,
            agency_time_price,
          });

          MessageAlert.success('정산내용이 수정되었습니다');
        } else {
          createCalculate.mutate({
            user_id: calModalData?.user_id,
            agency_id: parseInt(selectAgencyId),
            repetition_schedule_id:
              calModalData?.type === 'repetition_schedule'
                ? calModalData?.schedules[0]?.repetition_schedule_id
                : null,
            schedule_id:
              calModalData?.type === 'repetition_schedule'
                ? null
                : calModalData?.schedules[0]?.schedule_id,
            work_hour,
            time_price,
            agency_time_price,
            year: year,
            month: month,
          });

          MessageAlert.success('정산되었습니다');
        }
      } catch (err) {
        MessageAlert.error('정산하는데 오류가 발생하였습니다!');
        console.log('정산 에러!', err);
        throw err;
      }
    } else {
      MessageAlert.warning('취소되었습니다.');
    }
  };

  /**
   * 계산서 전송 팝업
   * --
   */
  const handlePopup = (t, data) => {
    const domain = DOMAIN_URL;

    setIsPopup(true);
    if (t === 'bill') {
      const f = data?.filter((item) => item?.calculate);
      const popupData = {
        total_calculate: totalCalculateData?.total_calculate_id
          ? totalCalculateData
          : null,
        agency_id: data[0]?.agency_id,
        agency_name: data[0]?.agency_name,
        agency_email: data[0]?.agency_email,
        agency_address: data[0]?.agency_address,
        agency_address_detail: data[0]?.agency_address_detail,
        chief_name: data[0]?.chief_name,
        company_number: data[0]?.company_number,
        year: year,
        month: month,
        calculate: f,
      };
      const encodeData = encrypt(JSON.stringify(popupData));

      window.open(
        `${domain}/#/popup/bill?data=${encodeURIComponent(encodeData)}`,
        `정산 팝업 ${encodeData}`,
        'width=1200,height=800'
      );
    } else {
      const popupData = {
        ...data,
        ...(agencies &&
          agencies?.filter((item) => item?.agency_id === selectAgencyId)[0]),
      };
      const encodeData = encrypt(JSON.stringify(popupData));
      window.open(
        `${domain}/#/popup/tax-bill?data=${encodeURIComponent(encodeData)}`,
        `계산서 팝업 ${encodeData}`,
        'width=1500,height=800'
      );
    }
  };

  /**
   * 토탈 정산 데이터 삭제
   * --
   */
  const handleTotalCalculateDelete = (type, data) => {
    const find = data?.data?.find(
      (item) => parseInt(item.year) === year && parseInt(item.month) === month
    );
    if (!find) {
      MessageAlert.error('전송된 메일이 없습니다.');
      return;
    }

    if (!find?.bill && type === 'bill') {
      MessageAlert.error('전송된 청구서 메일이 없습니다.');
      return;
    }
    if (!find?.tax_bill && type === 'tax_bill') {
      MessageAlert.error('전송된 계산서 메일이 없습니다.');
      return;
    }

    if (
      window.confirm(
        `정말 ${type === 'bill' ? '청구서' : '계산서'}를 삭제 하시겠습니까?`
      )
    ) {
      if (type === 'bill') {
        updateTotalCalculate.mutate({
          total_calculate_id: find?.total_calculate_id,
          bill: null,
        });
      } else {
        updateTotalCalculate.mutate({
          total_calculate_id: find?.total_calculate_id,
          tax_bill: null,
        });
      }
      MessageAlert.success('삭제 되었습니다.');
    }
  };

  /**
   * 정산 삭제
   * --
   */
  const handleCalculateDelete = (data) => {
    if (!data?.calculate) {
      MessageAlert.error('정산 완료 후 시도해 주세요.');
      return;
    }
    if (
      window.confirm(
        '정말 삭제하시겠습니까? 강사정산서 및 기관정산에는 반영되지 않습니다.'
      )
    ) {
      deleteCalculate.mutate({
        calculate_id: data?.calculate?.calculate_id,
      });
      MessageAlert.success('삭제되었습니다.');
    }
    return;
  };

  /**
   * 청구 아이템 체크
   * --
   */
  // const handleCheckList = (data) => {
  //   if (checkList?.includes(data)) {
  //     const filter = checkList?.filter((item) => item !== data);

  //     setCheckList(filter);
  //   } else {
  //     setCheckList((prev) => [...prev, data]);
  //   }
  // };

  /**
   * 청구 아이템 전체 선택
   * --
   */
  // const handleCheckAll = (e) => {
  //   if (e.target.checked) {
  //     const data = calculateData?.filter((item) => item?.calculate);
  //     setCheckList(data);
  //   } else {
  //     setCheckList([]);
  //   }
  //   setCheckAll(e.target.checked);
  // };

  /* ===== HOOKS ===== */
  /**
   * 기관목록 정제
   * --
   */
  useEffect(() => {
    const newList = agencies?.map((item) => ({
      ...item,
    }));

    setAgencyList(newList);
    setSelectAgencyId(newList?.length ? newList[0]?.agency_id : 0);
  }, [agencies]);

  /**
   * 기관 총 정산 데이터 호출
   * --
   */
  useEffect(() => {
    totalCalculateRefetch();
  }, [selectAgencyId, totalCalculateRefetch]);

  /**
   * 해당 년/월에대한 총 정산 데이터 정제
   * --
   */
  useEffect(() => {
    if (totalCalculate?.data?.length) {
      const findData = totalCalculate?.data?.find(
        (item) =>
          parseInt(item?.year) === year && parseInt(item?.month) === month
      );

      // console.log('findData', findData);
      setTotalCalculateData(findData);
    } else {
      setTotalCalculateData({});
    }
  }, [totalCalculate, year, month]);
  // console.log('total', totalCalculateData);

  /**
   * 선택한 기관 라우팅
   * --
   */
  useEffect(() => {
    const agencyId = searchParams.get('agency');

    if (agencyId) {
      const id = decrypt(agencyId);
      setSelectAgencyId(Number(id));
    } else {
      setSelectAgencyId(agencyList?.length ? agencyList[0]?.agency_id : 0);
    }
  }, [navigation, searchParams, agencyList]);

  /**
   * 오늘 날짜 정제
   * --
   */
  useEffect(() => {
    const today = new Date();

    const y = today.getFullYear();
    const m = today.getMonth() + 1;

    setYear(y);
    setMonth(m);
  }, []);

  /**
   * 선택한 기관 스케쥴/정산 데이터 호출
   * --
   */
  useEffect(() => {
    scheduleRefetch();
    calculateRefetch();
  }, [selectAgencyId, year, month, scheduleRefetch, calculateRefetch]);

  /**
   * 스케쥴 / 정산 데이터 정제
   * --
   */
  useEffect(() => {
    if (schedules?.status === 200) {
      let scheduleList = [],
        repetitionScheduleList = [];
      schedules?.data?.forEach((item) => {
        if (item?.schedule_type === 'S') {
          if (item?.repetition_schedule_id) {
            repetitionScheduleList.push(item);
          } else {
            scheduleList?.push(item);
          }
        }
      });

      const groupedByRepetitionId = repetitionScheduleList?.reduce(
        (acc, item) => {
          const repetitionId = item?.repetition_schedule_id;

          if (!repetitionId) {
            if (!acc[null]) {
              acc[null] = [];
            }
            acc[null].push(item);
          } else {
            // repetition_schedule_id가 있는 경우
            if (!acc[repetitionId]) {
              acc[repetitionId] = [];
            }
            acc[repetitionId].push(item);
          }

          return acc;
        },
        {}
      );
      const resultArray = Object.values(groupedByRepetitionId);

      const newData = [
        ...(resultArray &&
          resultArray?.map((item) => {
            const agencyData = agencyList?.filter(
              (subitem) => item[0]?.agency_id === subitem?.agency_id
            )[0];
            const categoryData = categories?.filter(
              (subitem) => item[0]?.category_id === subitem?.category_id
            )[0];
            const userData = teachers?.filter(
              (subitem) => item[0]?.user_id === subitem?.user_id
            )[0];

            return {
              user_id: userData?.user_id,
              user_name: userData?.name,
              user_email: userData?.email,
              agency_id: agencyData?.agency_id,
              agency_name: agencyData?.name,
              agency_address: agencyData?.address,
              agency_address_detail: agencyData?.address_detail,
              agency_email: agencyData?.email,
              chief_name: agencyData?.chief_name,
              company_number: agencyData?.company_number,
              category_name: categoryData?.category_name,
              type: 'repetition_schedule',
              parents_name: categories?.filter(
                (subitem) => subitem?.category_id === categoryData?.parents_id
              )[0]?.category_name,
              schedules: [...item],
              calculate: calculate?.data?.filter(
                (subitem) =>
                  subitem?.repetition_schedule_id ===
                  item[0]?.repetition_schedule_id
              )[0],
            };
          })),
        ...(scheduleList &&
          scheduleList?.map((item) => {
            const agencyData = agencyList?.filter(
              (subitem) => item?.agency_id === subitem?.agency_id
            )[0];
            const categoryData = categories?.filter(
              (subitem) => item?.category_id === subitem?.category_id
            )[0];
            const userData = teachers?.filter(
              (subitem) => item?.user_id === subitem?.user_id
            )[0];

            return {
              user_id: userData?.user_id,
              user_name: userData?.name,
              user_email: userData?.email,
              agency_id: agencyData?.agency_id,
              agency_name: agencyData?.name,
              agency_email: agencyData?.email,
              agency_address: agencyData?.address,
              agency_address_detail: agencyData?.address_detail,
              category_name: categoryData?.category_name,
              parents_name: categories?.filter(
                (subitem) => subitem?.category_id === categoryData?.parents_id
              )[0]?.category_name,
              type: 'schedule',
              schedules: [item],
              calculate: calculate?.data?.filter(
                (subitem) => subitem?.schedule_id === item?.schedule_id
              )[0],
            };
          })),
      ];

      setCalculateData(newData);
    }
  }, [
    schedules,
    calculate,
    agencies,
    categories,
    selectAgencyId,
    agencyList,
    teachers,
  ]);
  // console.log('calculateData', calculateData);

  /**
   * 총 정산금액 계산
   * --
   */
  useEffect(() => {
    let total = 0;
    calculateData?.forEach((item) => {
      const { calculate } = item;
      if (calculate) {
        total += calculate?.agency_time_price * calculate?.work_hour;
      }
    });
    setTotalPrice(total);
  }, [calculateData]);

  /**
   * 캘린더 아이템 렌더링
   * --
   */
  useEffect(() => {
    setEventItems(events);

    let complete = 0,
      examine = 0;
    events?.forEach((item) => {
      if (item.user_check && item.agency_check) {
        complete++;
      } else {
        // 둘 중 하나라도 'N'일 경우
        examine++;
      }
    });

    const p = [
      {
        title: '진행강의',
        value: events?.length,
      },
      {
        title: '완료',
        value: complete,
      },
      {
        title: '검토중',
        value: examine,
      },
    ];
    setProgress(p);
  }, [events]);

  /**
   * 기관 검색
   * --
   */
  useEffect(() => {
    const list = agencyList;
    const filterList = list?.filter((item) =>
      item?.name?.includes(debouncedValue)
    );

    setFilterAgencyList(filterList);
  }, [debouncedValue, agencyList]);

  /**
   * 모달데이터 초기화
   * --
   */
  useEffect(() => {
    if (!isCalculateModal) {
      setCalInputData({
        work_hour: null,
        time_price: 40000,
        agency_time_price: 55000,
      });
      setCalModalData({});
    }
  }, [isCalculateModal]);

  /**
   * 팝업창 메시지 이벤트 감지
   * --
   */
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'refresh') {
        calculateRefetch();
        totalCalculateRefetch();
      } else if (event.data === 'closed') {
        // console.log('닫힘');
        setIsPopup(false);
      }
    };

    if (isPopup) {
      window.addEventListener('message', handleMessage);
    } else {
      window.removeEventListener('message', handleMessage);
    }

    return () => {
      window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line
  }, [isPopup, selectAgencyId]);

  /**
   * 날짜 querystring 값 정제
   * --
   */
  useEffect(() => {
    const splitDate = date?.split('-');
    setYear(parseInt(splitDate[0]));
    setMonth(parseInt(splitDate[1]));
  }, [date]);

  /**
   * 체크박스 전체선택 렌더링
   * --
   */
  // useEffect(() => {
  //   const cal = calculateData?.filter((item) => item?.calculate);

  //   if (cal?.length === checkList?.length) {
  //     setCheckAll(true);
  //   } else {
  //     setCheckAll(false);
  //   }
  // }, [checkList, calculateData]);

  /* ===== STYLES ===== */
  const styles = {
    container: {
      borderRadius: 10,
      boxShadow: '10px 10px 15px 0 rgba(0, 0, 0, 0.15)',
      margin: 5,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      textAlign: 'center',
      fontWeight: 700,
    },
    modalTitle: {
      textAlign: 'center',
      color: '#757575',
    },
    modalTxt: {
      color: '#757575',
      fontSize: 16,
    },
  };

  /* ===== RENDER ===== */
  return (
    <>
      {/* 다운로드 버튼 그룹 */}
      <Content
        padding={'0 15px'}
        maxWidth={'100%'}
        backgroundColor={'none'}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          layoutStyle={{ margin: 0 }}
          onClick={() => onExcelDownload(year, month)}
        >
          <span style={{ paddingRight: 10 }}>
            <DownloadOutlined />
          </span>
          {month}월 계산서 엑셀 파일
        </Button>
        <div>
          <Btn
            icon={<DownloadOutlined />}
            style={{ margin: '0 5px' }}
            onClick={() => {
              onDownload('bill', totalCalculateData?.bill);
              setPdfType('bill');
            }}
          >
            청구서
          </Btn>
          <Btn
            icon={<DownloadOutlined />}
            style={{ margin: '0 5px' }}
            onClick={() => {
              onDownload('tax_bill', totalCalculateData?.tax_bill);
              setPdfType('tax_bill');
            }}
          >
            계산서
          </Btn>
        </div>
      </Content>
      {/* /다운로드 버튼 그룹 */}

      <Content padding={0} maxWidth={'100%'} backgroundColor={'none'}>
        <Row>
          <Col x={6} style={{ padding: 5 }}>
            <Content maxWidth={'100%'} style={{ ...styles.container }}>
              <Title>기관목록</Title>
              <Input
                size="large"
                placeholder="기관명을 입력해주세요"
                style={{ marginTop: 30 }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Content maxWidth={'100%'} padding={0} style={{ marginTop: 30 }}>
                <Row>
                  <Col x={24}>
                    <List
                      style={{
                        background: '#F5F5F5',
                        border: '1px solid #E0E0E0',
                        borderRadius: 5,
                        height: 950,
                        overflowY: 'scroll',
                      }}
                    >
                      {filterAgencyList?.map((item) => (
                        <div
                          key={`agenecy-${item?.agency_id}`}
                          onClick={() =>
                            navigation(
                              `/admin/agency-calculate?date=${year}-${month}&agency=${encodeURIComponent(
                                encrypt(item?.agency_id)
                              )}`
                            )
                          }
                        >
                          <Row
                            style={{
                              borderBottom: '1px solid #E0E0E0',
                              padding: 10,
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                              position: 'relative',
                              top: 0,
                              left: 0,
                            }}
                          >
                            {selectAgencyId === item?.agency_id ? (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: `50%`,
                                  left: `50%`,
                                  width: '98%',
                                  height: '95%',
                                  background: '#F2D3DB',
                                  border: '1px solid #AB6576',
                                  borderRadius: 5,
                                  transform: `translate(-50%, -50%)`,
                                }}
                              ></div>
                            ) : null}

                            <Col x={4}>
                              <Avatar size={50}></Avatar>
                            </Col>
                            <Col x={8}>
                              <p
                                style={{
                                  color: '#9E9E9E',
                                  fontSize: 20,
                                  textAlign: 'center',
                                }}
                              >
                                {item?.name}
                              </p>
                            </Col>
                            <Col x={12}>
                              <p
                                style={{
                                  color: '#9E9E9E',
                                  fontSize: 18,
                                  textOverflow: 'ellipsis',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {item?.address}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </List>
                  </Col>
                </Row>
              </Content>
            </Content>
          </Col>
          <Col x={18}>
            <Row>
              <Col x={16} style={{ padding: 5 }}>
                <Content maxWidth={'100%'} style={{ ...styles.container }}>
                  <Title>스케쥴현황</Title>
                  <Content
                    maxWidth={'100%'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {progress?.map((item, index) => (
                      <div key={`item-${item?.title}-${index}`}>
                        <Statistic
                          title={item?.title}
                          value={item?.value}
                          suffix={'건'}
                        />
                      </div>
                    ))}
                  </Content>
                </Content>
              </Col>
              <Col x={8} style={{ padding: 5 }}>
                <Content
                  maxWidth={'100%'}
                  style={{
                    ...styles.container,
                  }}
                >
                  <Title>청구금액</Title>
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Title size={2} align={'center'}>
                      {totalCalculateData?.total_calculate_id
                        ? stringToMoneyFormat(
                            totalCalculateData?.total_price,
                            '원'
                          )
                        : '0원'}
                    </Title>
                  </div>
                </Content>
              </Col>
            </Row>
            <Row>
              <Col x={16} style={{ padding: 5 }}>
                <Content maxWidth={'100%'} style={{ ...styles.container }}>
                  <Content
                    maxWidth={'100%'}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Btn
                      size="large"
                      icon={<LeftOutlined />}
                      onClick={() => handleDate('prev')}
                    />
                    <Title style={{ padding: '0 10px' }}>
                      {year}년 {month}월
                    </Title>
                    <Btn
                      size="large"
                      icon={<RightOutlined />}
                      onClick={() => handleDate('next')}
                    />
                  </Content>
                  <Calendar
                    items={eventItems}
                    height={800}
                    isHeader={false}
                    dateTime={`${year}-${month}`}
                    detail
                    program
                    update
                  />
                </Content>
              </Col>
              <Col x={8} style={{ padding: 5 }}>
                <Content maxWidth={'100%'} style={{ ...styles.container }}>
                  <Title>정산내역</Title>
                  <Content
                    maxWidth={'100%'}
                    padding={0}
                    style={{ marginTop: 30 }}
                  >
                    <Row>
                      <Col x={6}>
                        <p style={{ ...styles.title }}>프로그램</p>
                      </Col>
                      <Col x={6}>
                        <p style={{ ...styles.title }}>강사명</p>
                      </Col>
                      <Col x={6}>
                        <p style={{ ...styles.title }}>정산일</p>
                      </Col>
                      <Col x={6}>
                        <p style={{ ...styles.title }}>정산금액</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col x={24}>
                        <List
                          style={{
                            background: '#F5F5F5',
                            border: '1px solid #E0E0E0',
                            borderRadius: 5,
                            height: 700,
                            overflowY: 'scroll',
                          }}
                        >
                          {calculateData?.map((item, index) => (
                            <React.Fragment key={`calculate-${index}`}>
                              <Row
                                style={{
                                  borderBottom: '1px solid #E0E0E0',
                                  padding: '5px 0',
                                }}
                              >
                                <Col x={6}>
                                  <p
                                    style={{
                                      textAlign: 'center',
                                      color: '#9E9E9E',
                                    }}
                                  >
                                    {item?.category_name}
                                  </p>
                                </Col>
                                <Col x={6}>
                                  <p
                                    style={{
                                      textAlign: 'center',
                                      color: '#9E9E9E',
                                    }}
                                  >
                                    {item?.user_name}
                                  </p>
                                </Col>
                                <Col x={6}>
                                  <p
                                    style={{
                                      textAlign: 'center',
                                      color: '#9E9E9E',
                                    }}
                                  >
                                    {item?.calculate
                                      ? moment(
                                          item?.calculate?.created_at
                                        ).format('YYYY.MM.DD')
                                      : '-'}
                                  </p>
                                </Col>
                                <Col x={6}>
                                  <p
                                    style={{
                                      textAlign: 'center',
                                      color: '#9E9E9E',
                                    }}
                                  >
                                    {item?.calculate
                                      ? stringToMoneyFormat(
                                          item?.calculate?.time_price *
                                            item?.calculate?.work_hour,
                                          '원'
                                        )
                                      : '-'}
                                  </p>
                                </Col>
                              </Row>
                            </React.Fragment>
                          ))}
                        </List>
                      </Col>
                    </Row>
                  </Content>
                  <Button
                    type="secondary"
                    color="secondary"
                    layoutStyle={{ width: '100%' }}
                    style={{ width: '100%' }}
                    onClick={() => setIsOpened(!isOpened)}
                  >
                    상세보기
                  </Button>
                </Content>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>

      {/* 정산내역 상세 */}
      <ModalLayout
        type={'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={'right'}
        width={'30%'}
        height={'100%'}
        style={{ padding: 0 }}
        bodyStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'}>
          <Row>
            <Col x={24}>
              <Content maxWidth={'100%'} padding={'0 0'}>
                <Row>
                  <Title style={{ width: '60%' }}>청구하기</Title>
                  <Btn
                    type="text"
                    style={{
                      width: '19%',
                      padding: '0',
                      marginRight: '3px',
                      borderRadius: '2px',
                      color: 'rgba(0, 0, 0, 0.85)',
                      border: '1px solid #d9d9d9',
                    }}
                    onClick={() =>
                      handleTotalCalculateDelete('bill', totalCalculate)
                    }
                  >
                    청구서
                    <DeleteOutlined
                      style={{
                        color: '#FB4E4E',
                        fontSize: '17px',
                        marginLeft: '1px',
                      }}
                    />
                  </Btn>
                  <Btn
                    type="text"
                    style={{
                      width: '19%',
                      padding: '0',
                      borderRadius: '2px',
                      color: 'rgba(0, 0, 0, 0.85)',
                      border: '1px solid #d9d9d9',
                    }}
                    onClick={() =>
                      handleTotalCalculateDelete('tax_bill', totalCalculate)
                    }
                  >
                    계산서
                    <DeleteOutlined
                      style={{
                        color: '#FB4E4E',
                        fontSize: '17px',
                        marginLeft: '1px',
                      }}
                    />
                  </Btn>
                </Row>
              </Content>
              <Content
                maxWidth={'100%'}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  border: '1px solid #E0E0E0',
                  background: '#F5F5F5',
                  borderRadius: 10,
                  marginTop: 15,
                }}
              >
                <p style={{ fontSize: 18, color: '#757575' }}>총 청구금액</p>
                <h4 style={{ fontSize: 24, margin: 0 }}>
                  {stringToMoneyFormat(totalPrice, '원')}
                </h4>
              </Content>
              <Content maxWidth={'100%'} padding={'15px 0'}>
                <Row>
                  {/* <Col x={24} style={{ marginBottom: 15 }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox
                        checked={checkAll}
                        onChange={(e) => handleCheckAll(e)}
                      >
                        전체선택
                      </Checkbox>
                      <p>선택됨 [{checkList?.length}]</p>
                    </div>
                  </Col> */}
                  <Col x={24}>
                    <List
                      style={{
                        background: '#F5F5F5',
                        border: '1px solid #E0E0E0',
                        borderRadius: 5,
                        height: '80vh',
                        overflowY: 'scroll',
                      }}
                    >
                      {calculateData?.map((item, index) => (
                        <React.Fragment key={`calculate-${index}`}>
                          <Collapse expandIconPosition="end">
                            <Collapse.Panel
                              header={
                                <div
                                  style={{
                                    padding: 15,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {/* {item?.calculate ? (
                                    <div onClick={(e) => e.stopPropagation()}>
                                      <Checkbox
                                        style={{ marginRight: 10 }}
                                        checked={
                                          checkList?.includes(item)
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => handleCheckList(item)}
                                      />
                                    </div>
                                  ) : null} */}

                                  <h4
                                    style={{
                                      fontSize: 18,
                                      display: 'flex',
                                      alignItems: 'center',
                                      margin: 0,
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 14,
                                        padding: '3px 5px',
                                        background: '#E3A4AF',
                                        borderRadius: 4,
                                        color: '#FFFFFF',
                                        marginRight: 10,
                                      }}
                                    >
                                      {item?.parents_name}
                                    </span>
                                    {item?.category_name} - {item?.user_name} (
                                    {item?.schedules?.length})
                                    <span
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        marginLeft: 10,
                                        color: item?.calculate
                                          ? '#2AC769'
                                          : '#FB4E4E',
                                      }}
                                    >
                                      {item?.calculate ? '정산완료' : '미정산'}
                                    </span>
                                  </h4>
                                </div>
                              }
                            >
                              <Row padding={10}>
                                <Col x={24}>
                                  <Row>
                                    <Col x={6}>
                                      <Title size={5}>진행일</Title>
                                    </Col>
                                    <Col x={6}>
                                      <Title size={5}>진행시간</Title>
                                    </Col>
                                    <Col x={6}>
                                      <Title size={5}>강사 확인</Title>
                                    </Col>
                                    <Col x={6}>
                                      <Title size={5}>기관 확인</Title>
                                    </Col>
                                  </Row>
                                  {item?.schedules?.map((subitem, subindex) => (
                                    <React.Fragment
                                      key={`cal-subitem-${subindex}`}
                                    >
                                      <Row x={24}>
                                        <Col x={6}>
                                          <p style={{ ...styles.modalTxt }}>
                                            {moment(
                                              subitem?.start_date
                                            )?.format('YYYY.MM.DD')}
                                          </p>
                                        </Col>
                                        <Col x={6}>
                                          <p style={{ ...styles.modalTxt }}>
                                            {handleCalculateTime(
                                              subitem?.start_date,
                                              subitem?.end_date
                                            )}
                                            h
                                          </p>
                                        </Col>
                                        <Col x={6}>
                                          <p style={{ ...styles.modalTxt }}>
                                            {subitem?.user_check
                                              ? `${moment(
                                                  subitem?.user_check
                                                )?.format('YYYY.MM.DD')}`
                                              : '-'}
                                          </p>
                                        </Col>
                                        <Col x={6}>
                                          <p style={{ ...styles.modalTxt }}>
                                            {subitem?.agency_check
                                              ? `${moment(
                                                  subitem?.agency_check
                                                )?.format('YYYY.MM.DD')}`
                                              : '-'}
                                          </p>
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  ))}
                                  <Row style={{ marginTop: 10 }}>
                                    <Col x={6}>
                                      <Statistic
                                        title={'정산시간'}
                                        value={
                                          item?.calculate?.work_hour
                                            ? item?.calculate?.work_hour
                                            : '-'
                                        }
                                        suffix={
                                          item?.calculate?.work_hour && 'h'
                                        }
                                        layoutStyle={{
                                          border: 'none',
                                          padding: 0,
                                          margin: '0 auto',
                                        }}
                                        titleStyle={{ fontSize: 14 }}
                                        valueStyle={{ fontSize: 20 }}
                                      />
                                    </Col>
                                    <Col x={6}>
                                      <Statistic
                                        title={'강사정산시급'}
                                        value={stringToMoneyFormat(
                                          item?.calculate?.time_price
                                        )}
                                        suffix={'원'}
                                        layoutStyle={{
                                          border: 'none',
                                          padding: 0,
                                          margin: '0 auto',
                                        }}
                                        titleStyle={{ fontSize: 14 }}
                                        valueStyle={{ fontSize: 20 }}
                                      />
                                    </Col>
                                    <Col x={6}>
                                      <Statistic
                                        title={'기관정산시급'}
                                        value={stringToMoneyFormat(
                                          item?.calculate?.agency_time_price
                                        )}
                                        suffix={'원'}
                                        layoutStyle={{
                                          border: 'none',
                                          padding: 0,
                                          margin: '0 auto',
                                        }}
                                        titleStyle={{ fontSize: 14 }}
                                        valueStyle={{ fontSize: 20 }}
                                      />
                                    </Col>
                                    <Col x={6}>
                                      <Statistic
                                        title={'청구금액'}
                                        value={stringToMoneyFormat(
                                          item?.calculate?.agency_time_price *
                                            item?.calculate?.work_hour
                                        )}
                                        suffix={'원'}
                                        layoutStyle={{
                                          border: 'none',
                                          padding: 0,
                                          margin: '0 auto',
                                        }}
                                        titleStyle={{ fontSize: 14 }}
                                        valueStyle={{ fontSize: 20 }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row style={{ marginTop: 10 }}>
                                    <Col x={24}>
                                      <Button
                                        style={{
                                          width: '50%',
                                          margin: '0 auto',
                                        }}
                                        onClick={() =>
                                          handleCalculateModal(item)
                                        }
                                      >
                                        정산하기
                                      </Button>
                                      {item?.calculate ? (
                                        <Btn
                                          type="text"
                                          style={{
                                            width: '5%',
                                            padding: '0',
                                            position: 'absolute',
                                            top: '0',
                                            right: '11px',
                                          }}
                                          onClick={() =>
                                            handleCalculateDelete(item)
                                          }
                                        >
                                          <DeleteOutlined
                                            style={{
                                              color: '#FB4E4E',
                                              fontSize: '17px',
                                            }}
                                          />
                                        </Btn>
                                      ) : (
                                        ''
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Collapse.Panel>
                          </Collapse>
                        </React.Fragment>
                      ))}
                    </List>
                  </Col>
                </Row>
              </Content>
              <Content maxWidth={'100%'} padding={0}>
                <Row>
                  <Col x={12}>
                    <Button
                      style={{
                        width: '95%',
                        height: 54,
                        // margin: '0 auto',
                      }}
                      disabled={totalPrice !== 0 ? false : true}
                      onClick={() => handlePopup('bill', calculateData)}
                    >
                      청구 메일발송
                    </Button>
                  </Col>
                  <Col x={12}>
                    <Button
                      color="secondary"
                      style={{
                        width: '95%',
                        height: 54,
                        // margin: '0 auto',
                        float: 'right',
                      }}
                      disabled={
                        totalCalculateData?.total_calculate_id ? false : true
                      }
                      onClick={() =>
                        handlePopup('tax_bill', totalCalculateData)
                      }
                    >
                      계산서 메일발송
                    </Button>
                  </Col>
                </Row>
              </Content>
            </Col>
          </Row>
        </Content>
      </ModalLayout>

      {/* 정산 모달 */}
      <ModalLayout
        type={'modal'}
        open={isCalculateModal}
        onCancel={setIsCalculateModal}
        width={800}
        height={500}
        closable
        zIndex={5500}
      >
        <Content maxWidth={'100%'}>
          <Row>
            <Col x={24}>
              <Title>
                {calModalData?.category_name} - {calModalData?.user_name}
              </Title>
              <Content maxWidth={'100%'} padding={0} style={{ marginTop: 10 }}>
                <Row>
                  <Col x={24}>
                    <Row>
                      <Col x={6}>
                        <Title size={5}>진행일</Title>
                      </Col>
                      <Col x={6}>
                        <Title size={5}>진행시간</Title>
                      </Col>
                      <Col x={6}>
                        <Title size={5}>강사 확인</Title>
                      </Col>
                      <Col x={6}>
                        <Title size={5}>기관 확인</Title>
                      </Col>
                    </Row>
                    {calModalData?.schedules?.map((subitem, subindex) => (
                      <React.Fragment key={`calmodal-subitem-${subindex}`}>
                        <Row x={24}>
                          <Col x={6}>
                            <p style={{ ...styles.modalTxt }}>
                              {moment(subitem?.start_date)?.format(
                                'YYYY.MM.DD'
                              )}
                            </p>
                          </Col>
                          <Col x={6}>
                            <p style={{ ...styles.modalTxt }}>
                              {handleCalculateTime(
                                subitem?.start_date,
                                subitem?.end_date
                              )}
                              h
                            </p>
                          </Col>
                          <Col x={6}>
                            <p style={{ ...styles.modalTxt }}>
                              {subitem?.user_check
                                ? `${moment(subitem?.user_check)?.format(
                                    'YYYY.MM.DD'
                                  )}`
                                : '-'}
                            </p>
                          </Col>
                          <Col x={6}>
                            <p style={{ ...styles.modalTxt }}>
                              {subitem?.agency_check
                                ? `${moment(subitem?.agency_check)?.format(
                                    'YYYY.MM.DD'
                                  )}`
                                : '-'}
                            </p>
                          </Col>
                        </Row>
                      </React.Fragment>
                    ))}
                    <Row style={{ marginTop: 20 }}>
                      <Col x={8} padding={5}>
                        <div>
                          <p style={{ color: '#757575', fontWeight: 700 }}>
                            정산시간
                          </p>
                          <Input
                            size="large"
                            placeholder="정산할 시간을 숫자로 입력해주세요"
                            suffix="h"
                            value={calInputData?.work_hour}
                            onChange={(e) =>
                              handleChange('work_hour', e.target.value)
                            }
                          />
                        </div>
                      </Col>
                      <Col x={8} padding={5}>
                        <div>
                          <p style={{ color: '#757575', fontWeight: 700 }}>
                            강사정산시급
                          </p>
                          <Input
                            size="large"
                            placeholder="시급을 입력해주세요"
                            suffix="원"
                            value={calInputData?.time_price}
                            onChange={(e) =>
                              handleChange('time_price', e.target.value)
                            }
                          />
                        </div>
                      </Col>
                      <Col x={8} padding={5}>
                        <div>
                          <p style={{ color: '#757575', fontWeight: 700 }}>
                            기관정산시급
                          </p>
                          <Input
                            size="large"
                            placeholder="시급을 입력해주세요"
                            suffix="원"
                            value={calInputData?.agency_time_price}
                            onChange={(e) =>
                              handleChange('agency_time_price', e.target.value)
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                      <Col x={24}>
                        <Button
                          style={{ width: '100%', height: 54 }}
                          onClick={() => handleCalculate()}
                        >
                          정산하기
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Content>
            </Col>
          </Row>
        </Content>
      </ModalLayout>

      {/* PDF 모달 템플릿 */}
      <ModalLayout open={opened} onCancel={setOpened} closable>
        <Content maxWidth={'100%'} backgroundColor={'none'}>
          <Row>
            <Col x={24}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(htmlTemplate),
                }}
              ></div>
            </Col>
          </Row>
          <Row>
            <Col x={24}>
              <Button
                style={{ margin: '0 auto', width: '50%', height: 54 }}
                onClick={() => onPDFDownload(pdfType, totalCalculateData)}
              >
                다운로드
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

AgencyCalculatePresenter.defaultProps = {};

export default AgencyCalculatePresenter;
