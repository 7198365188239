import { useNavigate } from "react-router-dom";

function Footer() {
  /* Router */

  /* State */
  const navigate = useNavigate();
  /* Functions */

  /* Hooks */
  /* Render */
  return (
    <div className="footer-main">
      <ul className="footer-content">
        <li onClick={() => navigate("/")}></li>
      </ul>
    </div>
  );
}

export default Footer;
