import React, { useState, useEffect, useMemo } from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  Button,
  Calendar,
  ModalLayout,
} from 'components';
import {
  List,
  Button as Btn,
  Input,
  Avatar,
  Empty,
  Badge,
  Radio,
  Card,
  Select,
  Statistic,
} from 'antd';
import VirtualList from 'rc-virtual-list';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { encrypt, decrypt } from 'utils/Crypto';
import moment from 'moment';
import UseDebounce from 'utils/useDebounce';
import { ScheduleApi, RepetitionScheduleApi, FileApi, CalculateApi } from 'api';
import {
  STORE_URL,
  handleDeleteCheck,
  timeInputFormatter,
  handleDiffTime,
  DOMAIN_URL,
} from 'utils';
import { LeftOutlined, RightOutlined, DeleteOutlined } from '@ant-design/icons';
import MessageAlert from 'utils/MessageAlert';
import { FileUploadManager } from 'utils/FileUploadManager';
import { useQueryClient } from 'react-query';

const weekItems = [
  {
    label: <p style={{ color: 'red' }}>일요일</p>,
    value: 'SUN',
  },
  {
    label: <p>월요일</p>,
    value: 'MON',
  },
  {
    label: <p>화요일</p>,
    value: 'TUE',
  },
  {
    label: <p>수요일</p>,
    value: 'WED',
  },
  {
    label: <p>목요일</p>,
    value: 'THU',
  },
  {
    label: <p>금요일</p>,
    value: 'FRI',
  },
  {
    label: <p style={{ color: 'blue' }}>토요일</p>,
    value: 'SAT',
  },
];

const SchedulesPresenter = (props) => {
  /* ===== Props ===== */
  const { teachers, agencies, categories, repetitionSchedules, programs } =
    props;

  /* ===== Router ===== */
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const date = searchParams.get('date');

  /* ===== State ===== */
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = UseDebounce(searchValue, 300);
  const [programSearchValue, setProgramSearchValue] = useState('');
  const debouncedProgramValue = UseDebounce(programSearchValue, 300);
  const [teacherList, setTeacherList] = useState([]);
  const [filterTeacherList, setFilterTeacherList] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [week, setWeek] = useState(null);
  const [day, setDay] = useState(1);
  const [weeklyItemList, setWeeklyItemList] = useState([]);
  const [selectUserId, setSelectUserId] = useState(0);
  const [eventItems, setEventItems] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [isPlanetOpened, setIsPlanetOpened] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [modalType, setModalType] = useState(1);
  const [programItems, setProgramItems] = useState([]);
  const [programValue, setProgramValue] = useState({});
  // 일광등록 아이템
  const [repetitionEvents, setRepetitionEvents] = useState([]);
  const [scheduleItems, setScheduleItems] = useState([]);
  // 주별/월별 일정 생성 모달
  const [programModal, setProgramModal] = useState(false);
  const [programData, setProgramData] = useState({
    type: 1,
    user_id: null,
    agency_id: null,
    category_id: null,
    content: null,
    week: null,
    weekday: 'MON',
    start_time: null,
    end_time: null,
    schedule_type: 'S',
    registrant: 'teacher',
  });
  const [selectUserInfo, setSelectUserInfo] = useState({});
  // 기관 Select 아이템
  const [agencyList, setAgencyList] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [majorItems, setMajorItems] = useState([]);
  const [majorValue, setMajorValue] = useState(0);
  const [majorDetailItems, setMajorDetailItems] = useState([]);
  const [majorDetailValue, setMajorDetailValue] = useState(0);
  // 강의 일지 모달
  const [journalModal, setJournalModal] = useState(false);
  const [journalData, setJournalData] = useState([]);

  /* ===== MUTATE ===== */
  const { data: schedules, refetch: scheduleRefetch } =
    ScheduleApi.GetSchedules({
      user_id: parseInt(selectUserId),
      date: `${year}-${month}`,
    });
  const { data: calculate, refetch: calculateRefetch } =
    CalculateApi.GetCalculates({
      user_id: parseInt(selectUserId),
      date: `${year}-${month}`,
    });
  const createSchedule = ScheduleApi.CreateSchedule({
    onSuccess: async (data, variables) => {
      if (variables?.file) {
        const res = await FileUploadManager('S', variables?.file);

        if (res.status === 200) {
          createScheduleFile.mutate({
            schedule_id: data?.data?.schedule_id,
            file_name: res.file_name,
            file_url: res.name,
            type: res.type,
          });
        }
      }
    },
  });
  const createScheduleFile = FileApi.CreateScheduleFile();
  const createRepetitionSchedule =
    RepetitionScheduleApi.CreateRepetitionSchedule();
  const updateRepetitionSchedule =
    RepetitionScheduleApi.UpdateRepetitionSchedule();
  const deleteRepetitionSchedule =
    RepetitionScheduleApi.DeleteRepetitionSchedule();

  /**
   * 캘린더 아이템 정제
   * --
   */
  const events = useMemo(() => {
    const newAgencies = agencies?.map((item) => ({
      agency_id: item?.agency_id,
      agency_name: item?.name,
      agency_address: item?.address,
      agency_address_detail: item?.address_detail,
      agency_type: item?.type,
      agency_phone: item?.phone,
      agency_email: item?.email,
      chief_name: item?.chief_name,
    }));

    const newSchedules = schedules?.data?.map((item) => ({
      ...item,
      ...(newAgencies &&
        newAgencies?.filter(
          (subitem) => subitem.agency_id === item.agency_id
        )[0]),
      category_name: categories?.filter(
        (subitem) => subitem?.category_id === item?.category_id
      )[0]?.category_name,
    }));

    const s = newSchedules?.map((item, index) => ({
      id: item?.schedule_id,
      title: `${item?.category_name} (${moment(item?.start_date).format(
        'HH:mm'
      )} ~ ${moment(item?.end_date).format('HH:mm')})`,
      start: new Date(item?.start_date),
      end: new Date(item?.end_date),
      user_confirm: item?.user_confirm,
      agency_confirm: item?.agency_confirm,
      type: item?.schedule_type,
      content: item?.content,
      name: `${item?.agency_name} (${item?.agency_type})`,
      email: item?.agency_email,
      phone: item?.agency_phone,
      file: item?.file,
      chief_name: item?.chief_name,
      address: item?.agency_address,
      address_detail: item?.address_detail,
      agency_id: item?.agency_id,
      registrant: item?.registrant,
      user_id: item?.user_id,
      category_id: item?.category_id,
      category_name: item?.category_name,
      is_calculate: handleDeleteCheck(item, calculate?.data),
      repetition_schedule_id: item?.repetition_schedule_id,
    }));
    return s;
  }, [schedules, agencies, categories, calculate]);

  /* ===== Hooks ===== */
  /**
   * 선택한 강사 스케쥴/정산 데이터 호출
   * --
   */
  useEffect(() => {
    scheduleRefetch();
    calculateRefetch();
  }, [selectUserId, year, month, scheduleRefetch, calculateRefetch]);

  /**
   * 강사목록 정제
   * --
   */
  useEffect(() => {
    const newList = teachers?.map((item) => ({
      ...item,
      profileImage:
        item?.files?.length &&
        item?.files?.filter((subitem) => subitem?.type === 'P')?.pop()
          ?.file_url,
    }));

    setTeacherList(newList);
    setSelectUserId(newList?.length ? newList[0]?.user_id : 0);
  }, [teachers]);

  /**
   * 선택한 강사 라우팅
   * --
   */
  useEffect(() => {
    const teacherId = searchParams.get('teacher');

    if (teacherId) {
      const id = decrypt(teacherId);
      setSelectUserId(Number(id));
    } else {
      setSelectUserId(teacherList?.length ? teacherList[0]?.user_id : 0);
    }
  }, [navigate, searchParams, teacherList]);

  /**
   * 캘린더 아이템 렌더링
   * --
   */
  useEffect(() => {
    setEventItems(events);
  }, [events]);

  /**
   * 강사 검색
   * --
   */
  useEffect(() => {
    const list = teacherList;
    const filterList = list?.filter((item) =>
      item?.name?.includes(debouncedValue)
    );

    setFilterTeacherList(filterList);
  }, [debouncedValue, teacherList]);

  /**
   * 선택한 강사 정보 정제
   * --
   */
  useEffect(() => {
    if (selectUserId) {
      const teacher = teacherList?.filter(
        (item) => item?.user_id === selectUserId
      )[0];

      setSelectUserInfo(teacher);
    }
  }, [selectUserId, teacherList]);

  /**
   * 반복스케쥴 값 초기화
   * --
   */
  useEffect(() => {
    if (modalType === 1) {
      setWeek(null);
    } else {
      setWeek(0);
    }
  }, [modalType]);

  /**
   * 반복 스케쥴 정제
   * --
   */
  useEffect(() => {
    let newItems;
    const userRepetitionSchedule = repetitionSchedules?.filter(
      (item) => item?.user_id === selectUserId
    );
    if (modalType === 1) {
      newItems = weekItems?.map((item) => ({
        ...item,
        items: userRepetitionSchedule?.filter(
          (subitem) =>
            subitem?.weekday === item?.value && subitem?.week === null
        ),
      }));
    } else {
      const list = [1, 2, 3, 4];
      newItems = list?.map((item, index) => [
        ...weekItems?.map((subitem) => ({
          ...subitem,
          items: userRepetitionSchedule?.filter(
            (thirditem) =>
              thirditem?.weekday === subitem?.value && thirditem?.week === index
          ),
        })),
      ]);
    }
    setWeeklyItemList(newItems);
  }, [repetitionSchedules, week, day, modalType, selectUserId]);

  /**
   * 필터 값에 따른 교안 아이템 정제
   * --
   */
  useEffect(() => {
    const newItem = programs
      ?.filter((item) =>
        majorValue === 0 ? item : Number(item?.parents_id) === majorValue
      )
      ?.filter((item) =>
        majorDetailValue === 0 ? item : item?.category_id === majorDetailValue
      )
      ?.filter((item) =>
        debouncedProgramValue === ''
          ? item
          : item?.content?.title?.includes(debouncedProgramValue)
      );

    setProgramItems(newItem);
  }, [majorValue, majorDetailValue, debouncedProgramValue, programs]);

  /**
   * 기관 Select 아이템 정제
   * --
   */
  useEffect(() => {
    const newItem = agencies?.map((item) => ({
      label: (
        <div>
          <h4 style={{ fontWeight: 700 }}>
            {item?.name}{' '}
            <span style={{ fontSize: 12, fontWeight: 400 }}>
              ({item?.type})
            </span>
          </h4>
          <p style={{ color: '#BDBDBD' }}>{`${item?.address} ${
            item?.address_detail ? item?.address_detail : ''
          }`}</p>
        </div>
      ),
      value: item?.agency_id,
    }));
    setAgencyList(newItem);
  }, [agencies]);

  /**
   * 교안등록모달 Select 아이템 정제
   * --
   */
  useEffect(() => {
    if (categories) {
      let major = [],
        majorDetail = [];
      categories?.forEach((item) => {
        if (item?.type === 'M') {
          major.push(item);
        } else if (item?.type === 'D') {
          majorDetail.push(item);
        }
      });

      const items = major?.map((item) => ({
        label: item?.category_name,
        options: majorDetail
          ?.filter((subitem) => subitem?.parents_id === item?.category_id)
          ?.map((subitem) => ({
            label: subitem?.category_name,
            value: subitem?.category_id,
          })),
      }));
      setTreeData(items);
    }
  }, [categories]);

  /**
   * 전문분야 셀렉트 아이템 정제
   * --
   */
  useEffect(() => {
    const major = categories?.filter((item) => item?.type === 'M');
    if (major) {
      const m = major?.map((item) => ({
        label: item?.category_name,
        value: Number(item?.category_id),
      }));

      const items = [
        {
          label: '전체보기',
          value: 0,
        },
        ...m,
      ];
      setMajorValue(0);
      setMajorItems(items);
    } else {
      setMajorValue(0);
      setMajorItems([]);
    }
  }, [categories]);

  /**
   * 전문분야에 따른 상세분야 아이템 정제
   * --
   */
  useEffect(() => {
    if (majorValue) {
      if (majorValue === 0) {
        setMajorDetailValue(0);
        setMajorDetailItems([]);
        return;
      }

      const majorDetail = categories?.filter((item) => item?.type === 'D');

      const d = majorDetail
        ?.filter((item) => Number(item?.parents_id) === majorValue)
        ?.map((item) => ({
          label: item?.category_name,
          value: Number(item?.category_id),
        }));

      if (d && d?.length) {
        const items = [
          {
            label: '전체보기',
            value: 0,
          },
          ...d,
        ];
        setMajorDetailValue(0);
        setMajorDetailItems(items);
      } else {
        setMajorDetailValue(0);
        setMajorDetailItems([
          {
            label: '전체보기',
            value: 0,
          },
        ]);
      }
    } else {
      setMajorDetailValue(0);
      setMajorDetailItems([
        {
          label: '전체보기',
          value: 0,
        },
      ]);
    }
  }, [majorValue, categories]);

  /**
   * 날짜 querystring 값 정제
   * --
   */
  useEffect(() => {
    const splitDate = date?.split('-');
    setYear(parseInt(splitDate[0]));
    setMonth(parseInt(splitDate[1]));
  }, [date]);

  /**
   * 강의 일지 데이터 정제
   * --
   */
  useEffect(() => {
    const newSchedules = schedules?.data?.map((item) => ({
      ...item,
      category_name: categories?.filter(
        (subitem) => subitem?.category_id === item?.category_id
      )[0]?.category_name,
      agency_name: agencies?.filter(
        (subitem) => subitem?.agency_id === item?.agency_id
      )[0]?.name,
      address: agencies?.filter(
        (subitem) => subitem?.agency_id === item?.agency_id
      )[0]?.address,
      agency_signature: agencies?.filter(
        (subitem) => subitem?.agency_id === item?.agency_id
      )[0]?.signature,
      teacher_name: teachers?.filter(
        (subitem) => subitem?.user_id === item?.user_id
      )[0]?.name,
      signature: teachers?.filter(
        (subitem) => subitem?.user_id === item?.user_id
      )[0]?.signature,
    }));
    let arr = [];
    newSchedules?.forEach((item, index) => {
      if (!item?.repetition_schedule_id) {
        arr.push({ key: `null-${index}`, data: [item] });
      } else {
        const ext = arr.findIndex(
          (subitem) => subitem?.key === `${item?.repetition_schedule_id}`
        );
        if (ext === -1) {
          arr.push({ key: `${item?.repetition_schedule_id}`, data: [item] });
        } else {
          const newData = [...arr[ext]?.data, item];
          arr[ext].data = newData;
        }
      }
    });
    setJournalData(arr);
  }, [schedules, categories, agencies, teachers]);

  /**
   *
   */
  useEffect(() => {
    if (!scheduleModal) setProgramData({});
  }, [scheduleModal]);

  /* ===== Functions ===== */
  /**
   * 날짜 값 변경
   * --
   */
  const handleDate = (type) => {
    let m = month,
      y = year;
    if (type === 'prev') {
      --m;

      if (m === 0) {
        m = 12;
        --y;
      }
    } else {
      ++m;

      if (m > 12) {
        m = 1;
        ++y;
      }
    }
    navigate(
      `/admin/teacher-schedules?date=${y}-${m}${
        selectUserId && `&teacher=${encodeURIComponent(encrypt(selectUserId))}`
      }`
    );
  };

  /**
   * 주/월별 일정관리 모달
   * --
   */
  const handleSchedule = () => {
    setIsPlanetOpened(!isPlanetOpened);
  };

  /**
   * 일정 일괄등록
   * --
   */
  const handleBulkSchedule = () => {
    if (scheduleItems?.length) {
      const confirm = window.confirm('일정을 일괄 등록하시겠습니까?');
      if (confirm) {
        try {
          createSchedule.mutate(scheduleItems);
          MessageAlert.success('일정이 일괄등록되었습니다!');
          setIsOpened(false);
        } catch (err) {
          MessageAlert.error('일정 일괄등록에 실패하였습니다!');
          console.log('일정일괄등록실패!', err);
          throw err;
        }
      }
    } else {
      MessageAlert.warning('등록할 스케쥴이 없습니다!');
      return;
    }
  };

  /**
   * 프로그램 데이터 바인딩 함수
   * --
   */
  const handleProgramChage = (t, v) => {
    let newData = { ...programData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setProgramData(d);
  };

  /**
   * 반복 스케쥴 등록/수정/삭제
   * --
   */
  const handleRepeatSchedule = (type, data) => {
    const validateCheck = () => {
      if (
        programData?.type === 2 &&
        (programData?.week === null || programData?.week === undefined)
      ) {
        MessageAlert.warning('반복할 주를 선택해주세요!');
        return false;
      }
      if (!programData?.weekday) {
        MessageAlert.warning('반복할 요일을 선택해주세요!');
        return false;
      }
      if (!programData?.start_time) {
        MessageAlert.warning('시작시간을 입력해주세요!');
        return false;
      }
      if (!programData?.end_time) {
        MessageAlert.warning('종료시간을 입력해주세요!');
        return false;
      }
      const reg = /^([0-9]{1,2}):([0-5][0-9])$/;
      if (
        !reg.test(programData?.start_time) ||
        !reg.test(programData?.end_time)
      ) {
        MessageAlert.warning('HH:mm 형태로 시간을 입력해주세요');
        return;
      }
      if (
        programData?.start_time > '24:00' ||
        programData?.end_time > '24:00'
      ) {
        MessageAlert.warning('24:00시 이후로는 입력이 불가능합니다!');
        return;
      }
      if (programData?.start_time >= programData?.end_time) {
        MessageAlert.warning('종료시간은 시작시간 이후로만 입력가능합니다!');
        return false;
      }
      // 두 시간 차이 계산
      const diffTime = handleDiffTime(
        programData?.start_time,
        programData?.end_time,
        'h'
      );
      if (diffTime < 1) {
        MessageAlert.warning('1시간 미만의 스케줄은 등록할 수 없습니다.');
        return;
      }
      if (!programData?.agency_id) {
        MessageAlert.warning('기관을 선택해주세요!');
        return false;
      }
      if (!programData?.category_id) {
        MessageAlert.warning('프로그램을 선택해주세요!');
        return false;
      }

      return true;
    };

    let confirm;
    switch (type) {
      case 'C':
      case 'c':
        if (!validateCheck()) return;

        confirm = window.confirm('반복 일정을 등록하시겠습니까?');
        if (confirm) {
          try {
            createRepetitionSchedule.mutate({
              ...programData,
              week: programData?.type === 1 ? null : programData?.week,
              user_id: selectUserId,
              start_time: programData?.start_time,
              end_time: programData?.end_time,
              content: {
                ...programValue,
              },
            });
            MessageAlert.success('성공적으로 등록되었습니다');
          } catch (err) {
            console.log('반복일정 등록 실패!', err);
            MessageAlert.error('반복일정을 등록하는데 실패하였습니다!');
            throw err;
          }
        }

        break;
      case 'U':
      case 'u':
        if (!validateCheck()) return;

        confirm = window.confirm('반복 일정을 수정하시겠습니까?');
        if (confirm) {
          try {
            const { created_at, ...d } = data;
            updateRepetitionSchedule.mutate({
              ...d,
              start_time: programData?.start_time,
              end_time: programData?.end_time,
              content: {
                ...programValue,
              },
            });
          } catch (err) {
            console.log('반복일정 수정 실패!', err);
            MessageAlert.error('반복일정을 수정하는데 실패하였습니다!');
            throw err;
          }
        }

        break;
      case 'D':
      case 'd':
        confirm = window.confirm('일정을 정말 삭제하시겠습니까?');
        if (confirm) {
          try {
            const { repetition_schedule_id } = data;
            deleteRepetitionSchedule.mutate({
              repetition_schedule_id: repetition_schedule_id,
            });
            MessageAlert.success('삭제되었습니다');
          } catch (err) {
            console.log('반복일정 삭제 실패!', err);
            MessageAlert.error('반복일정을 삭제하는데 실패하였습니다!');
            throw err;
          }
        }
        break;
      default:
        break;
    }
    setScheduleModal(false);
    setProgramValue({});
    setProgramData({
      type: 1,
      user_id: null,
      agency_id: null,
      category_id: null,
      content: null,
      week: null,
      weekday: 'MON',
      start_time: null,
      end_time: null,
      schedule_type: 'S',
      registrant: 'teacher',
    });
  };

  /**
   * 반복일정 날짜 계산
   * --
   */
  const handleRepetitionSchedule = () => {
    /**
     * 해당 월에 날짜 계산하는 함수
     * --
     */
    const calculateDate = (data) => {
      let newScheduleData = [];
      data?.forEach((item) => {
        const {
          user_id,
          agency_id,
          category_id,
          content,
          start_time,
          end_time,
          agency_name,
          category_name,
          repetition_schedule_id,
          agency_type,
          agency_address,
          agency_address_detail,
        } = item;

        const today = new Date(`${year} ${month}`);
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        const weekdayIndex = weekItems?.findIndex(
          (subitem) => subitem?.value === item?.weekday
        );

        if (item?.week !== null) {
          // 월 반복일 경우
          const firstDayOfWeek = firstDayOfMonth.getDay();
          let difference = weekdayIndex - firstDayOfWeek;

          if (difference < 0) {
            difference += 7;
          }
          const targetDate = new Date(firstDayOfMonth);
          targetDate.setDate(1 + difference + item.week * 7);

          newScheduleData.push({
            user_id: user_id,
            agency_id: agency_id,
            category_id: category_id,
            content: { ...content },
            start_date: `${moment(targetDate).format(
              'YYYY-MM-DD'
            )} ${start_time}`,
            end_date: `${moment(targetDate).format('YYYY-MM-DD')} ${end_time}`,
            agency_name: agency_name,
            category_name: category_name,
            repetition_schedule_id: repetition_schedule_id,
            agency_type: agency_type,
            agency_address: agency_address,
            agency_address_detail: agency_address_detail,
          });
        } else {
          // 주 반복일 경우
          let weekDates = [];

          for (
            let d = firstDayOfMonth;
            d <= lastDayOfMonth;
            d.setDate(d.getDate() + 1)
          ) {
            if (d.getDay() === weekdayIndex) {
              weekDates.push(new Date(d));
            }
          }

          weekDates.forEach((date) => {
            newScheduleData.push({
              user_id: user_id,
              agency_id: agency_id,
              category_id: category_id,
              content: { ...content?.content },
              start_date: `${moment(date).format('YYYY-MM-DD')} ${start_time}`,
              end_date: `${moment(date).format('YYYY-MM-DD')} ${end_time}`,
              agency_name: agency_name,
              category_name: category_name,
              repetition_schedule_id: repetition_schedule_id,
              agency_type: agency_type,
              agency_address: agency_address,
              agency_address_detail: agency_address_detail,
            });
          });
        }
      });

      const exist = [],
        noExist = [];

      newScheduleData?.forEach((item) => {
        const findItem = schedules?.data?.find(
          (subitem) =>
            subitem?.repetition_schedule_id === item?.repetition_schedule_id
        );

        if (findItem) {
          exist.push({
            ...item,
            exist: true,
          });
        } else {
          noExist.push({
            ...item,
            exist: false,
          });
        }
      });
      const eventList = [...exist, ...noExist];
      const events = eventList?.map((item, index) => ({
        id: index,
        title: (
          <p>
            {item?.category_name} ({moment(item?.start_date).format('HH:mm')} ~{' '}
            {moment(item?.end_date).format('HH:mm')})<br />
            <span>{item?.agency_name}</span>
          </p>
        ),
        start: new Date(item?.start_date),
        end: new Date(item?.end_date),
        type: item?.exist ? 'S' : 'N',
        category_name: item?.category_name,
        name: `${item?.agency_name} (${item?.agency_type})`,
        address: item?.agency_address,
        address_detail: item?.agency_address_detail,
      }));
      setRepetitionEvents(events);
      setScheduleItems(noExist);
      setIsOpened(!isOpened);
    };

    const userRepetitionSchedule = repetitionSchedules?.filter(
      (item) => item?.user_id === selectUserId
    );
    calculateDate(userRepetitionSchedule);
  };

  /**
   * 교안 선택 관리
   * --
   */
  const handleSelectProgram = (d) => {
    setProgramValue(d);
    setProgramModal(false);
  };

  /**
   * 옵션필터 검색
   * --
   * @param {*} value
   * @param {*} label
   */
  const handleFilterOption = (value, label) => {
    const agencyName = label.props?.children[0]?.props?.children[0];
    return agencyName?.toLowerCase()?.includes(value?.toLowerCase());
  };

  /**
   * 팝업 메시지
   * --
   */
  const handlePopupMessage = (event) => {
    if (event.data === 'refresh') {
      queryClient.invalidateQueries('getSchedules');
    }
  };

  /**
   * 강의 일지 팝업 핸들러
   * --
   */
  const handlePopup = (data) => {
    const popData = data[0];
    const domain = DOMAIN_URL;
    const popupData = {
      program: popData?.category_name,
      agency_name: popData?.agency_name,
      teacher_name: popData?.teacher_name,
      address: popData?.address,
      year: year,
      month: month,
      start_time: moment(popData?.start_date).format('HH:mm'),
      end_time: moment(popData?.end_date).format('HH:mm'),
      schedules: [...data],
    };
    const encodeData = encrypt(JSON.stringify(popupData));
    window.open(
      `${domain}/#/popup/journal?data=${encodeURIComponent(encodeData)}`,
      '팝업',
      'width=1200,height=800'
    );
  };

  /* ===== Render ===== */
  return (
    <>
      <Content padding={0} maxWidth={'100%'} backgroundColor={'none'}>
        <Row>
          <Col x={6} style={{ padding: 5 }}>
            <Content maxWidth={'100%'} className={'admin_container'}>
              <Title>강사목록</Title>
              <Input
                size="large"
                placeholder="강사명을 입력해주세요"
                style={{ marginTop: 30 }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Content maxWidth={'100%'} padding={0} style={{ marginTop: 30 }}>
                <Row>
                  <Col x={24}>
                    <List
                      style={{
                        background: '#F5F5F5',
                        border: '1px solid #E0E0E0',
                        borderRadius: 5,
                        height: 1000,
                        overflowY: 'scroll',
                      }}
                    >
                      {filterTeacherList?.map((item) => (
                        <div
                          key={`teacher-${item?.user_id}-${item?.user_name}`}
                          onClick={() =>
                            navigate(
                              `/admin/teacher-schedules?date=${year}-${month}&teacher=${encodeURIComponent(
                                encrypt(item?.user_id)
                              )}`
                            )
                          }
                        >
                          <Row
                            style={{
                              borderBottom: '1px solid #E0E0E0',
                              padding: 10,
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                              position: 'relative',
                              top: 0,
                              left: 0,
                            }}
                          >
                            {selectUserId === item?.user_id ? (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: `50%`,
                                  left: `50%`,
                                  width: '98%',
                                  height: '95%',
                                  background: '#F2D3DB',
                                  border: '1px solid #AB6576',
                                  borderRadius: 5,
                                  transform: `translate(-50%, -50%)`,
                                }}
                              ></div>
                            ) : null}

                            <Col x={4}>
                              <Avatar
                                size={50}
                                src={
                                  item?.profileImage &&
                                  `${STORE_URL}/users/${item?.user_id}/${item?.profileImage}`
                                }
                              ></Avatar>
                            </Col>
                            <Col x={8}>
                              <p
                                style={{
                                  color: '#9E9E9E',
                                  fontSize: 20,
                                  textAlign: 'center',
                                }}
                              >
                                {item?.name}
                              </p>
                            </Col>
                            <Col x={12}>
                              <p
                                style={{
                                  color: '#9E9E9E',
                                  fontSize: 18,
                                }}
                              >
                                {item?.address}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </List>
                  </Col>
                </Row>
              </Content>
            </Content>
          </Col>
          <Col x={18}>
            <Row>
              <Col x={24} style={{ padding: 5 }}>
                <Content maxWidth={'100%'} className={'admin_container'}>
                  <Title>강사정보</Title>
                  <Content
                    maxWidth={'100%'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Row style={{ width: '100%' }}>
                      <Col x={4} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'강사명'}
                          value={selectUserInfo?.name}
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                      <Col x={4} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'생년월일'}
                          value={moment(selectUserInfo?.birthday)?.format(
                            'YYYY.MM.DD'
                          )}
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                      <Col x={4} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'성별'}
                          value={selectUserInfo?.gender === 'M' ? '남' : '여'}
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                      <Col x={4} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'프로필'}
                          value={
                            selectUserInfo?.profile ? '등록완료' : '미등록'
                          }
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                      <Col x={4} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'진행강의'}
                          value={schedules?.data?.length}
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                      <Col x={4} style={{ marginBottom: 10 }}></Col>
                      <Col x={4} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'연락처'}
                          value={selectUserInfo?.phone}
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                      <Col x={6} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'이메일'}
                          value={selectUserInfo?.email}
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                      <Col x={14} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'주소'}
                          value={`${
                            selectUserInfo?.address
                              ? selectUserInfo?.address
                              : '-'
                          }\n${
                            selectUserInfo?.address_detail
                              ? selectUserInfo?.address_detail
                              : ''
                          }`}
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                    </Row>
                  </Content>
                </Content>
              </Col>
            </Row>
            <Row>
              <Col x={24} style={{ padding: 5 }}>
                <Content maxWidth={'100%'} className={'admin_container'}>
                  <Content
                    maxWidth={'100%'}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Btn
                      size="large"
                      icon={<LeftOutlined />}
                      onClick={() => handleDate('prev')}
                    />
                    <Title style={{ padding: '0 10px' }}>
                      {year}년 {month}월
                    </Title>
                    <Btn
                      size="large"
                      icon={<RightOutlined />}
                      onClick={() => handleDate('next')}
                    />
                  </Content>
                  <Calendar
                    items={eventItems}
                    height={800}
                    isHeader={false}
                    dateTime={`${year}-${month}`}
                    detail
                    update
                    add
                    program
                    onPopupMessage={handlePopupMessage}
                    teacherId={selectUserId}
                  />
                  <Content
                    maxWidth={'100%'}
                    padding={8}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <Button
                        layoutStyle={{ margin: 0 }}
                        onClick={() => setJournalModal(!journalModal)}
                      >
                        {month}월 강의 일지
                      </Button>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        layoutStyle={{ margin: 0, marginRight: 15 }}
                        color="secondary"
                        onClick={() => handleSchedule()}
                      >
                        주별/월별 일정 관리
                      </Button>
                      <Button
                        layoutStyle={{ margin: 0 }}
                        onClick={() => handleRepetitionSchedule()}
                      >
                        {month}월 일정 일괄등록
                      </Button>
                    </div>
                  </Content>
                </Content>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>

      {/* 주별/월별 일정관리 모달 */}
      <ModalLayout
        title={'주별/월별 일정관리'}
        type={'modal'}
        open={isPlanetOpened}
        onCancel={setIsPlanetOpened}
        width={700}
        height={'70%'}
        closable
        bodyStyle={{ padding: '15px' }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <Content maxWidth={'100%'}>
                <Radio.Group
                  defaultValue={1}
                  size="large"
                  onChange={(e) => {
                    setModalType(e.target.value);
                    handleProgramChage('type', e.target.value);
                  }}
                >
                  <Radio.Button value={1}>주별 프로그램</Radio.Button>
                  <Radio.Button value={2}>월별 프로그램</Radio.Button>
                </Radio.Group>
              </Content>
              {modalType === 1 ? (
                // 주별 프로그램
                <Content maxWidth={'100%'}>
                  <Row>
                    <Col x={24}>
                      <ul
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                      >
                        {weeklyItemList?.map((item, index) => (
                          <li
                            key={item?.value}
                            style={{
                              width: 'calc(100% / 7)',
                              border:
                                day === index
                                  ? '1px solid #40A9FF'
                                  : '1px solid #E0E0E0',
                              textAlign: 'center',
                              padding: '10px 0',
                              cursor: 'pointer',
                              color: day === index && '#40A9FF',
                            }}
                            onClick={() => {
                              setDay(index);
                              handleProgramChage('weekday', item?.value);
                            }}
                          >
                            <Badge count={item?.items?.length} offset={[15, 0]}>
                              {item?.label}
                            </Badge>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col x={24} style={{ height: 500 }}>
                      {weeklyItemList[day] &&
                      weeklyItemList[day]?.items?.length ? (
                        <List
                          grid={{ gutter: 12, column: 2 }}
                          dataSource={weeklyItemList[day]?.items}
                          renderItem={(item, index) => (
                            <List.Item key={`item-${index}`}>
                              <Card
                                title={
                                  <p>
                                    {item?.category_name}{' '}
                                    <span
                                      style={{ fontSize: 14, color: '#BDBDBD' }}
                                    >
                                      (
                                      {`${item?.start_time} ~ ${item?.end_time}`}
                                      )
                                    </span>
                                  </p>
                                }
                                hoverable
                                extra={
                                  <Btn
                                    type="text"
                                    style={{ padding: 0 }}
                                    onClick={(e) => {
                                      handleRepeatSchedule('D', item);
                                      e.stopPropagation();
                                    }}
                                  >
                                    <DeleteOutlined
                                      style={{ color: '#FB4E4E' }}
                                    />
                                  </Btn>
                                }
                                onClick={() => {
                                  setProgramData({
                                    ...item,
                                    start_time: item?.start_time,
                                    end_time: item?.end_time,
                                    type: item?.week ? 2 : 1,
                                  });
                                  setScheduleModal(!scheduleModal);
                                  setProgramValue(item?.content);
                                }}
                              >
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {item?.agency_name}{' '}
                                  <span
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: '#BDBDBD',
                                    }}
                                  >
                                    ({item?.agency_type})
                                  </span>
                                </p>
                                <p>{item?.agency_address}</p>
                                <p>
                                  {item?.agency_address_detail
                                    ? item?.agency_address_detail
                                    : ''}
                                </p>
                              </Card>
                            </List.Item>
                          )}
                        />
                      ) : (
                        <Row
                          style={{
                            height: '100%',
                          }}
                        >
                          <Col x={24}>
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              style={{
                                height: '100%',
                                margin: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Content>
              ) : (
                // 월별 프로그램
                <Content maxWidth={'100%'}>
                  <Row>
                    <Col x={24}>
                      <Select
                        size="large"
                        style={{ width: '100%' }}
                        value={week}
                        options={[1, 2, 3, 4]?.map((item, index) => ({
                          label: `${item}주`,
                          value: index,
                        }))}
                        onChange={(e) => {
                          setWeek(e);
                          handleProgramChage('week', e);
                        }}
                        dropdownStyle={{ zIndex: 3500 }}
                      />
                    </Col>
                    <Col x={24} style={{ marginTop: 15 }}>
                      <ul
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                      >
                        {weeklyItemList[week]?.map((item, index) => (
                          <li
                            key={`${item?.value}-${index}`}
                            style={{
                              width: 'calc(100%/7)',
                              border:
                                day === index
                                  ? '1px solid #40A9FF'
                                  : '1px solid #E0E0E0',
                              textAlign: 'center',
                              padding: '10px 0',
                              cursor: 'pointer',
                              color: day === index && '#40A9FF',
                            }}
                            onClick={() => {
                              setDay(index);
                              handleProgramChage('weekday', item?.value);
                            }}
                          >
                            <Badge count={item?.items?.length} offset={[15, 0]}>
                              {item?.label}
                            </Badge>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col x={24} style={{ height: 500 }}>
                      {weeklyItemList[week] &&
                      weeklyItemList[week][day].items ? (
                        <List
                          grid={{ gutter: 12, column: 2 }}
                          dataSource={weeklyItemList[week][day].items}
                          renderItem={(item, index) => (
                            <List.Item key={`item-${index}-${index}`}>
                              <Card
                                title={
                                  <p>
                                    {item?.category_name}{' '}
                                    <span
                                      style={{ fontSize: 14, color: '#BDBDBD' }}
                                    >
                                      (
                                      {`${item?.start_time} ~ ${item?.end_time}`}
                                      )
                                    </span>
                                  </p>
                                }
                                hoverable
                                extra={
                                  <Btn
                                    type="text"
                                    style={{ padding: 0 }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRepeatSchedule('D', item);
                                    }}
                                  >
                                    <DeleteOutlined
                                      style={{ color: '#FB4E4E' }}
                                    />
                                  </Btn>
                                }
                                onClick={() => {
                                  setProgramData({
                                    ...item,
                                    start_time: item?.start_time,
                                    end_time: item?.end_time,
                                    type: item?.week ? 2 : 1,
                                  });
                                  setProgramValue(item?.content);
                                  setScheduleModal(!scheduleModal);
                                }}
                              >
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {item?.agency_name}{' '}
                                  <span
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: '#BDBDBD',
                                    }}
                                  >
                                    ({item?.agency_type})
                                  </span>
                                </p>
                                <p>{item?.agency_address}</p>
                                <p>
                                  {item?.agency_address_detail
                                    ? item?.agency_address_detail
                                    : ''}
                                </p>
                              </Card>
                            </List.Item>
                          )}
                        />
                      ) : (
                        <Row
                          style={{
                            height: '100%',
                          }}
                        >
                          <Col x={24}>
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              style={{
                                height: '100%',
                                margin: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Content>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Col x={24}>
              <Button
                style={{ width: '100%', height: 56, fontSize: 20 }}
                onClick={() => setScheduleModal(!scheduleModal)}
              >
                일정추가
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>

      {/* 일정 일괄등록 모달 */}
      <ModalLayout
        title={
          modalType === 1 ? '주별/월별 일정관리' : `${month}월 일정 일괄등록`
        }
        type={'modal'}
        open={isOpened}
        onCancel={setIsOpened}
        width={'80%'}
        height={'70%'}
        closable
        bodyStyle={{ padding: '40px' }}
      >
        <Content
          maxWidth={'100%'}
          padding={0}
          style={{ maxHeight: 1000, overflowY: 'scroll' }}
        >
          <Row>
            <Col x={24}>
              <Title align={'center'} size={1}>{`${year}.${month}`}</Title>
              <ul>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: 30,
                      height: 10,
                      background: '#4B55F8',
                      marginRight: 10,
                    }}
                  ></span>{' '}
                  등록된 일정
                </li>
                <li style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: 30,
                      height: 10,
                      background: '#BDBDBD',
                      marginRight: 10,
                    }}
                  ></span>{' '}
                  미등록 일정
                </li>
              </ul>
              <Calendar
                dateTime={new Date(year, month - 1)}
                height={800}
                isHeader={false}
                items={repetitionEvents}
                detail
              />
            </Col>
          </Row>
        </Content>
        <Content maxWidth={'100%'} padding={0} style={{ marginTop: '50px' }}>
          <Button
            style={{ width: '100%', height: '50px', fontSize: '18px' }}
            onClick={() => handleBulkSchedule()}
          >
            등록
          </Button>
        </Content>
      </ModalLayout>

      {/* 주별/월별 일정 추가 모달 */}
      <ModalLayout
        title={'주별/월별 일정'}
        type={'modal'}
        open={scheduleModal}
        onCancel={setScheduleModal}
        placement={'right'}
        width={768}
        height={'70%'}
        closable
        bodyStyle={{ padding: '40px' }}
        zIndex={3600}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              {/* 타입 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={24}>
                  <Radio.Group
                    value={programData?.type}
                    size="large"
                    onChange={(e) => handleProgramChage('type', e.target.value)}
                  >
                    <Radio.Button value={1}>주별 프로그램</Radio.Button>
                    <Radio.Button value={2}>월별 프로그램</Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
              {/* 주 */}
              {programData?.type === 2 ? (
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 15,
                  }}
                >
                  <Col x={5}>
                    <Title>주</Title>
                  </Col>
                  <Col x={19}>
                    <Select
                      size="large"
                      style={{ width: '100%' }}
                      value={programData?.week}
                      options={[1, 2, 3, 4]?.map((item, index) => ({
                        label: `${item}주`,
                        value: index,
                      }))}
                      onChange={(e) => handleProgramChage('week', e)}
                      dropdownStyle={{ zIndex: 3700 }}
                    />
                  </Col>
                </Row>
              ) : null}
              {/* 요일 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={5}>
                  <Title>요일</Title>
                </Col>
                <Col x={19}>
                  <Radio.Group
                    value={programData?.weekday}
                    onChange={(e) =>
                      handleProgramChage('weekday', e.target.value)
                    }
                  >
                    {weekItems?.map((item) => (
                      <Radio value={item?.value} size="large" key={item?.value}>
                        {item?.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Col>
              </Row>
              {/* 시간 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={5}>
                  <Title>시간</Title>
                </Col>
                <Col
                  x={19}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Input
                    size="large"
                    style={{ width: 150 }}
                    placeholder="시작시간"
                    value={programData?.start_time}
                    onChange={(e) =>
                      handleProgramChage(
                        'start_time',
                        timeInputFormatter(e.target.value)
                      )
                    }
                    maxLength={5}
                  />
                  <p style={{ padding: '0 10px' }}>~</p>
                  <Input
                    size="large"
                    style={{ width: 150 }}
                    placeholder="종료시간"
                    value={programData?.end_time}
                    onChange={(e) =>
                      handleProgramChage(
                        'end_time',
                        timeInputFormatter(e.target.value)
                      )
                    }
                    maxLength={5}
                  />
                </Col>
              </Row>
              {/* 기관 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={5}>
                  <Title>기관</Title>
                </Col>
                <Col x={19}>
                  <Select
                    size="large"
                    options={agencyList}
                    value={programData?.agency_id}
                    style={{ width: '100%' }}
                    dropdownStyle={{ zIndex: 3700 }}
                    onChange={(e) => handleProgramChage('agency_id', e)}
                    placeholder="기관을 선택해주세요"
                    showSearch
                    filterOption={(e, option) =>
                      handleFilterOption(e, option?.label)
                    }
                    disabled={programData?.repetition_schedule_id}
                  />
                </Col>
              </Row>
              {/* 프로그램 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={5}>
                  <Title>프로그램</Title>
                </Col>
                <Col x={19}>
                  <Select
                    size="large"
                    options={treeData}
                    value={programData?.category_id}
                    style={{ width: '100%' }}
                    dropdownStyle={{ zIndex: 3700 }}
                    onChange={(e) => handleProgramChage('category_id', e)}
                    placeholder="프로그램을 선택해주세요"
                  />
                </Col>
              </Row>
              {/* 교안 */}
              {/* <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={5}>
                  <Title>교안</Title>
                </Col>
                <Col x={19} padding={0}>
                  {programValue?.category ? (
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontSize: 18,
                          display: 'flex',
                          alignItems: 'center',
                          color: '#000000',
                        }}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            marginRight: 10,
                            display: 'inline-block',
                            padding: '3px 5px',
                            background: '#E3A4AF',
                            color: '#FFFFFF',
                          }}
                        >
                          {programValue?.category}
                        </span>
                        {programValue?.title}
                      </p>
                      <button
                        style={{
                          padding: '0px 5px',
                          cursor: 'pointer',
                          background: '#FFFFFF',
                          border: '1px solid #EEEEEE',
                        }}
                        onClick={() => setProgramValue({})}
                      >
                        x
                      </button>
                    </Content>
                  ) : (
                    <Content maxWidth={'100%'} padding={0}>
                      <Btn type="primary" onClick={() => setProgramModal(true)}>
                        교안검색
                      </Btn>
                    </Content>
                  )}
                </Col>
              </Row> */}
            </Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Col x={24}>
              {programData?.repetition_schedule_id ? (
                <Content
                  maxWidth={'100%'}
                  padding={0}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-arount',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    color="secondary"
                    layoutStyle={{ width: '45%' }}
                    style={{ width: '100%', height: 56, fontSize: 20 }}
                    onClick={() => handleRepeatSchedule('U', programData)}
                  >
                    수정
                  </Button>
                  <Button
                    layoutStyle={{ width: '45%' }}
                    style={{ width: '100%', height: 56, fontSize: 20 }}
                    onClick={() => handleRepeatSchedule('D', programData)}
                  >
                    삭제
                  </Button>
                </Content>
              ) : (
                <Button
                  style={{ width: '100%', height: 56, fontSize: 20 }}
                  onClick={() => handleRepeatSchedule('C')}
                >
                  일정추가
                </Button>
              )}
            </Col>
          </Row>
        </Content>
      </ModalLayout>

      {/* 프로그램 선택 모달 */}
      <ModalLayout
        title={'프로그램'}
        type={'modal'}
        open={programModal}
        onCancel={setProgramModal}
        width={768}
        height={'70%'}
        closable
        bodyStyle={{ padding: '40px' }}
        zIndex={3700}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={7} padding={5}>
              <p>구분</p>
              <Select
                value={majorValue}
                onChange={(e) => setMajorValue(e)}
                style={{ width: '100%' }}
                size="large"
                options={majorItems}
                dropdownStyle={{
                  zIndex: 3800,
                }}
              />
            </Col>
            <Col x={7} padding={5}>
              <p>구분상세</p>
              <Select
                value={majorDetailValue}
                onChange={(e) => setMajorDetailValue(e)}
                style={{ width: '100%' }}
                size="large"
                options={majorDetailItems}
                dropdownStyle={{
                  zIndex: 3800,
                }}
              />
            </Col>
            <Col x={10} padding={5}>
              <p>프로그램</p>
              <Input
                value={programSearchValue}
                onChange={(e) => setProgramSearchValue(e.target.value)}
                style={{ width: '100%' }}
                size="large"
                placeholder="프로그램명을 검색해주세요"
              />
            </Col>
          </Row>
          <Row>
            <Col x={24} style={{ marginTop: 20, border: '1px solid #EEEEEE' }}>
              <Row>
                <Col
                  x={5}
                  padding={5}
                  style={{
                    borderRight: '1px solid #EEEEEE',
                    color: '#BDBDBD',
                    fontWeight: 700,
                  }}
                >
                  구분
                </Col>
                <Col
                  x={5}
                  padding={5}
                  style={{
                    borderRight: '1px solid #EEEEEE',
                    color: '#BDBDBD',
                    fontWeight: 700,
                  }}
                >
                  구분상세
                </Col>
                <Col
                  x={14}
                  padding={5}
                  style={{
                    color: '#BDBDBD',
                    fontWeight: 700,
                  }}
                >
                  프로그램명
                </Col>
              </Row>
            </Col>
            <Col
              x={24}
              style={{
                border: '1px solid #EEEEEE',
                borderTop: 'none',
                height: 500,
                overflowY: 'scroll',
              }}
            >
              {programItems && programItems?.length ? (
                <List>
                  <VirtualList
                    data={programItems}
                    height={'100%'}
                    itemKey={'program_id'}
                  >
                    {(item) => (
                      <Row
                        key={item?.program_id}
                        style={{ borderBottom: '1px solid #EEEEEE' }}
                      >
                        <Col
                          x={5}
                          padding={'10px 5px'}
                          style={{
                            borderRight: '1px solid #EEEEEE',
                            color: '#000000',
                            fontWeight: 700,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {item?.parents_name}
                        </Col>
                        <Col
                          x={5}
                          padding={'10px 5px'}
                          style={{
                            borderRight: '1px solid #EEEEEE',
                            color: '#000000',
                            fontWeight: 700,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {item?.category_name}
                        </Col>
                        <Col
                          x={14}
                          padding={'10px 5px'}
                          style={{
                            color: '#000000',
                            fontWeight: 700,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          {item?.content?.title}
                          <Btn
                            onClick={() => handleSelectProgram(item?.content)}
                          >
                            선택
                          </Btn>
                        </Col>
                      </Row>
                    )}
                  </VirtualList>
                </List>
              ) : (
                <Row
                  style={{
                    borderBottom: '1px solid #EEEEEE',
                    height: '100%',
                  }}
                >
                  <Col x={24}>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      style={{
                        height: '100%',
                        margin: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Content>
      </ModalLayout>

      {/* 활동일지 리스트 모달 */}
      <ModalLayout
        title={'강의 일지'}
        type={'drawer'}
        open={journalModal}
        onCancel={setJournalModal}
        placement={'right'}
        width={500}
        closable
        bodyStyle={{ padding: '15px' }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              {journalData?.map((item) => (
                <div
                  key={item?.key}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #EEEEEE',
                    margin: '5px 0',
                    padding: '20px 10px',
                  }}
                >
                  <div>
                    <Title>{item?.data[0]?.category_name}</Title>
                    <p style={{ fontSize: 18 }}>{item?.data[0]?.agency_name}</p>
                  </div>
                  <Button
                    layoutStyle={{ margin: 0 }}
                    onClick={() => handlePopup(item?.data)}
                  >
                    보기
                  </Button>
                </div>
              ))}
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

SchedulesPresenter.defaultProps = {};

export default SchedulesPresenter;
